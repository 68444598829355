import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'
import HasLifetimeListenersMixin from '../mixins/HasLifetimeListeners.js'

const MIXINS = [
  HasEventsMixin,
  HasLifetimeListenersMixin,
];

/**
 * Represents a hand grab event from begining to end, with movements included
 *
 * May emit those events :
 * - moved          = after the hand moved during the grab event
 */
class GrabEvent {
  constructor(handRig) {
    MixinsComposer.construct(this, MIXINS);

    this._handRig = handRig;
    this._currentDecal = {x: 0, y: 0, z: 0};
    // this._accumulatedDecal = {x: 0, y: 0, z: 0};
    this._lastPos = null;

    this.registerLifetimeListeners(
      handRig.on('tracking-input-sanitized', this._onTrackingInputSanitized.bind(this)),
    );

    // @TODO just to grab initial pos right now, but it's ugly code
    if (this._handRig._lastHandData) {
      this._onTrackingInputSanitized(this._handRig._lastHandData);
    }
  }

  _onTrackingInputSanitized(handData) {
    if (!handData.keypoints || !handData.keypoints.length || !handData.keypoints3D || !handData.keypoints3D.length) {
      return;
    }

    const wristPoint = handData.keypoints[0];
    const wristPoint3D = handData.keypoints3D[0];

    if (this._lastPos === null) {
      this._lastPos = {
        x: wristPoint.x,
        y: wristPoint.y,
        z: wristPoint3D.z,
      };
    } else {
      this._currentDecal.x = wristPoint.x - this._lastPos.x;
      this._currentDecal.y = wristPoint.y - this._lastPos.y;
      this._currentDecal.z = wristPoint3D.z - this._lastPos.z;

      // this._accumulatedDecal.x += this._currentDecal.x;
      // this._accumulatedDecal.y += this._currentDecal.y;
      // this._accumulatedDecal.z += this._currentDecal.z;

      this._lastPos.x = wristPoint.x;
      this._lastPos.y = wristPoint.y;
      this._lastPos.z = wristPoint3D.z;

      if (this._currentDecal.x !== 0 || this._currentDecal.y !== 0 || this._currentDecal.z !== 0) {
        this.emit('moved', this._currentDecal);
      }
    }
  }

  dispose() {
    this.clearLifetimeListeners();
  }
}

MixinsComposer.extend(GrabEvent).with(...MIXINS);

export default GrabEvent;

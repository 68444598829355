import {
  PlaneGeometry,
  ShadowMaterial,
  Mesh,
} from 'three';

import CONFIG from '../../config.js';

/**
 * A plane that is transparent but can receive shadows
 */
class ShadowPlane extends Mesh {
  constructor(size = 1000) {
    const floorSegments = Math.max(1, Math.round(size / 10));
    const geometry = new PlaneGeometry(size, size, floorSegments, floorSegments);
    const material = new ShadowMaterial({
      opacity: 0.5,
      depthWrite: CONFIG.MATURE_CONTENT, // sometimes you gotta get your fingers in there...
      fog: false,
    });

    super(geometry, material);

    this.receiveShadow = true;
  }
}

export default ShadowPlane;

import CONFIG from './config.js';
import CONTEXT from './context.js';

const HALF_MOVE_RANGE = CONFIG.HAND_MOVEMENT_RANGE * 0.5;

/**
 * Convert my centered coordinates system into threejs coordinate system
 */
export function posFromCenteredToThreejs(pos) {
  pos.x = valueFromCenteredToThreejs(pos.x, 'x');
  pos.y = valueFromCenteredToThreejs(pos.y, 'y');

  if (typeof pos.z !== 'undefined') {
    pos.z = valueFromCenteredToThreejs(pos.z, 'z');
  }

  return pos;
}

export function valueFromCenteredToThreejs(val, axis = '') {
  if (!CONFIG.LEGACY_CENTERING) {
    return val * HALF_MOVE_RANGE;
  }

  switch (axis) {
    case 'x':
      return val * HALF_MOVE_RANGE * CONTEXT.APP.viewportRatio;
    break;

    default:
      return val * HALF_MOVE_RANGE;
    break;
  }
}

/**
 * Convert threejs coordinates system into my centered coordinate system
 */
export function posFromThreejsToCentered(pos) {
  pos.x = valueFromThreejsToCentered(pos.x, 'x');
  pos.y = valueFromThreejsToCentered(pos.y, 'y');

  if (typeof pos.z !== 'undefined') {
    pos.z = valueFromThreejsToCentered(pos.z, 'z');
  }

  return pos;
}

export function valueFromThreejsToCentered(val, axis = '') {
  if (!CONFIG.LEGACY_CENTERING) {
    return val / HALF_MOVE_RANGE;
  }

  switch (axis) {
    case 'x':
      return val / (HALF_MOVE_RANGE * CONTEXT.APP.viewportRatio);
    break;

    default:
      return val / HALF_MOVE_RANGE;
    break;
  }
}

/**
 * Helpers to show/hide threejs views after a delay
 */
const _viewVisibilityTimeouts = new Map();
export function setViewVisibility(view, visible = true, delay = 0) {
  const timeout = _viewVisibilityTimeouts.get(view);
  if (timeout) {
    clearTimeout(timeout);
    _viewVisibilityTimeouts.delete(view)
  }

  if (delay) {
    _viewVisibilityTimeouts.set(view, setTimeout(() => { view.visible = visible; }, delay));
  } else {
    view.visible = visible;
  }
}

/**
 * Simulate how css's object-fit cover / contain resize an element inside it's parent
 */
export function getResizeSpecs(containerWidth = 0, containerHeight = 0, elemWidth = 0, elemHeight = 0, coverWholeBackground = true) {
  let realElemWidth = elemWidth;
  let realElemHeight = elemHeight;
  let cummulatedScale = 1;

  const scaleRealSize = (scale = 1) => {
    realElemWidth *= scale;
    realElemHeight *= scale;
    cummulatedScale *= scale;
  };

  if (!coverWholeBackground) {
    // constrain vertically
    scaleRealSize(containerHeight / elemHeight);

    // constrain horizontally
    if (realElemWidth > containerWidth) {
      scaleRealSize(containerWidth / realElemWidth);
    }
  } else {
    // scale vertically
    scaleRealSize(containerHeight / elemHeight);

    // scale horizontally
    if (realElemWidth < containerWidth) {
      scaleRealSize(containerWidth / realElemWidth);
    }
  }

  return {
    coveringWidthRatio: realElemWidth / containerWidth,
    coveringHeightRatio: realElemHeight / containerHeight,
    contentScale: cummulatedScale,
    contentWidth: realElemWidth,
    contentHeight: realElemHeight,
  };
}

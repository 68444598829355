import {Color as ThreeColor} from 'three';

import {
  RadialVelocity,
  Vector3D,
  Alpha,
  Scale,
  Color,
} from 'three-nebula';

import AbstractParticlesEffect from './AbstractParticlesEffect.js';

const COLOR_A = new ThreeColor(0xeb659e);
const COLOR_B = new ThreeColor(0x0adef0);

class FireworkEffect extends AbstractParticlesEffect {

  _getInitializers() {
    return super._getInitializers().concat([
      new RadialVelocity(0.25, new Vector3D(0, 1, 0), 180),
    ]);
  }

  _getBehaviours() {
    return super._getBehaviours().concat([
      new Alpha(1, 0),
      new Scale(0.04, 0),
      new Color(COLOR_A, COLOR_B),
    ]);
  }
}

export default FireworkEffect;

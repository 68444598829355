import {Vector2, Vector3} from 'three';

/**
 * A way of reusing vectors for performance reasons
 */
class VectorsBank {
  constructor() {
    this._vector2Map = new Map();
    this._vector3Map = new Map();
  }

  getVector2(ident = '', x = 0, y = 0) {
    let value = this._vector2Map.get(ident);
    if (!value) {
      value = new Vector2(x, y);
      this._vector2Map.set(ident, value)
    } else {
      value.set(x, y);
    }

    return value;
  }

  getVector3(ident = '', x = 0, y = 0, z = 0) {
    let value = this._vector3Map.get(ident);
    if (!value) {
      value = new Vector3(x, y, z);
      this._vector3Map.set(ident, value)
    } else {
      value.set(x, y, z);
    }

    return value;
  }
}

export default VectorsBank;

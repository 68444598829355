import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'

const MIXINS = [
  HasEventsMixin,
];

// note: performance is better, but date is more widely supported
const TIME_HANDLE = ( performance || Date );

/**
 * Class used to track fps of something
 * note: copied code from https://github.com/mrdoob/stats.js/blob/master/src/Stats.js
 *
 * May emit those events :
 * - fps-tick       = after the current fps has been recalculated (aproximatively every seconds)
 * - fps-changed    = after the current fps has changed from previous value
 */
class FpsTracker {
  constructor() {
    MixinsComposer.construct(this, MIXINS);

    this._beginTime = this.prevTime = TIME_HANDLE.now();
    this._framesCount = 0;
    this._prevFps = 0;
  }

  begin() {
    this._beginTime = TIME_HANDLE.now();
  }

  end() {
    this._framesCount ++;
    const currentTime = TIME_HANDLE.now();

    if ( currentTime >= this.prevTime + 1000 ) {
      this._onFpsTick( Math.round(( this._framesCount * 1000 ) / ( currentTime - this.prevTime )) );
      this.prevTime = currentTime;
      this._framesCount = 0;
    }
  }

  _onFpsTick(nv) {
    this.emit('fps-tick', nv);

    if (this._prevFps !== nv) {
      this._prevFps = nv;
      this.emit('fps-changed', nv);
    }
  }
}

MixinsComposer.extend(FpsTracker).with(...MIXINS);

export default FpsTracker;

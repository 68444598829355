import PosesManager from '../managers/PosesManager.js';
import GrabEvent from '../events/GrabEvent.js';

import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'

const MIXINS = [
  HasEventsMixin,
];

/**
 * Abstract encapsulation of a persons hands
 *
 * May emit those events :
 * - grab-started         = after a grab event started
 * - swipe-started        = after a swipe event started
 * - user-unhappy         = after the user did a middle finger
 */
class AbstractPersonRig {
  constructor() {
    MixinsComposer.construct(this, MIXINS);
  }

  getHandRigs() {
    return [];
  }

  handleHandsTrackingInput(handsData) {
    // ...to implement
  }

  _registerHandRig(handRig) {
    handRig._handPoseDetector.on('poses-changed', (poses) => {
      PosesManager.setCurrentPoses(handRig, poses);
    });

    this._listenForInteraction(handRig, 'grab', 'fist');
    // this._listenForInteraction(handRig, 'swipe', 'pointer-hand');
    // this._listenForInteraction(handRig, 'zoom', 'victory-hand');

    // handRig._handPoseDetector.on('pose-begun', (pose) => {
    //   if (pose.ident === 'fu-hand') {
    //     this.emit('user-unhappy');
    //   }
    // });
  }

  _listenForInteraction(handRig, eventIdent, poseIdent) {
    const map = new Map();

    handRig._handPoseDetector.on('pose-begun', (pose) => {
      if (pose.ident === poseIdent) {
        let evt = map.get(handRig);

        if (!evt) {
          evt = new GrabEvent(handRig);
          map.set(handRig, evt);

          this.emit(eventIdent + '-started', evt);
        }
      }
    });

    handRig._handPoseDetector.on('pose-ended', (pose) => {
      if (pose.ident === poseIdent) {
        const evt = map.get(handRig);

        if (evt) {
          map.delete(handRig);
          evt.dispose();
        }
      }
    });
  }
}

MixinsComposer.extend(AbstractPersonRig).with(...MIXINS);

export default AbstractPersonRig;

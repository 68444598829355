// @TODO only because of this, many unused code might end up in our builds...
import * as THREE from 'three';

import System, {
  SpriteRenderer,
  Emitter,
  Rate,
  Life,
} from 'three-nebula';

// because this method logs something in console every time...
// @TODO to remove it this PR got merged : https://github.com/creativelifeform/three-nebula/pull/205
SpriteRenderer.prototype.logRendererType = function(){};

import CONTEXT from '../../context.js';

/**
 * This uses three-nebula, but I am not a big fan of it... lets give it a try anyway
 * https://github.com/creativelifeform/three-nebula/tree/master/src
 *
 * @TODO I might try proton instead ? https://github.com/drawcall/three.proton/
 */
class AbstractParticlesEffect extends THREE.Group {
  constructor(pps = 32, lifetime = 1) {
    super();
    this._pps = pps;
    this._lifetime = lifetime;
    this._accumulatedTime = 0;

    this.system = new System();
    this.renderer = new SpriteRenderer(this, THREE);
    this.emitter = new Emitter();

    this.emitter
      .setRate(this._getRate())
      .setInitializers(this._getInitializers())
      .setBehaviours(this._getBehaviours());

    this.system
      .addRenderer(this.renderer)
      .addEmitter(this.emitter);

    this.emitter.emit();

    // @TODO attempts to fix that it can't start right after the emit ! it start 2 seconds after !!!
    this._initialTime = this._lifetime * 0.5;
    this._accumulatedTime -= this._initialTime;
    this.emitter.update(this._initialTime);
    // this._accumulatedTime -= this.emitter.rate.startTime;
    // this._accumulatedTime -= this.emitter.rate.nextTime;
    // this.emitter.age = this._lifetime;
    // this.emitter.currentEmitTime = this._lifetime;
    // this.emitter.rate.nextTime = 0;
    // this.emitter.rate.startTime = 0;

    this._updateListener = CONTEXT.APP.on('update-loop', this._onUpdate.bind(this));
  }

  // to overwrite in sub class...
  _getRate() {
    return new Rate(this._pps, this._lifetime);
  }

  // to overwrite in sub class...
  _getInitializers() {
    return [
      new Life(this._lifetime, this._lifetime),
    ];
  }

  // to overwrite in sub class...
  _getBehaviours() {
    return [];
  }

  _onUpdate(deltaTime) {
    this.system.update(deltaTime);
    this._accumulatedTime += deltaTime;

    // @TODO it seems to take longer to start the system... so I multiply the time a bit -_-
    if (this._accumulatedTime >= this._lifetime) {
      this._dispose();
    }
  }

  // @TODO there is no way to cleanly stop the particles system, ...which is stupid
  // https://github.com/creativelifeform/three-nebula/discussions/147
  _dispose() {
    this.system.removeEmitter(this.emitter);
    this.system.removeRenderer(this.renderer);

    this.emitter.stopEmit();
    this.emitter.destroy();
    this.system.destroy();

    let particle;
    while (this.emitter.particles.length) {
      particle = this.emitter.particles.pop();
      particle.target.removeFromParent();
      particle.destroy();
    }

    this._updateListener.off();

    this.removeFromParent();
  }
}

export default AbstractParticlesEffect;

import * as tf from '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import * as handdetection from '@tensorflow-models/hand-pose-detection';
import * as mpHands from '@mediapipe/hands';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import { REVISION } from 'three';

import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'

import {IS_PROD} from '../env.js';
import CONFIG from '../config.js';
import {STATE, TUNABLE_FLAG_VALUE_RANGE_MAP} from './params.js';
import {setupDatGui} from './option_panel.js';
import {createUpdatesLoop} from '../utils/updates_loop.js';
import ViewportManager from '../managers/ViewportManager.js';
import LogsManager from '../managers/LogsManager.js';
import FpsManager from '../managers/FpsManager.js';
import UserCamera from '../medias/UserCamera.js';
import StatsPanel from '../debug/StatsPanel.js';

// @TODO used mpHands.VERSION before, but this seem to be older than the installed package...
// take that version from the package itself plz, not just hardcoded value !
const MP_HANDS_VERSION = '0.4.1646424915';
const TF_WASM_VERSION = tfjsWasm.version_wasm;

if (!IS_PROD) {
  console.log('TensorFlow: v' + TF_WASM_VERSION);
  console.log('MediaPipe: v' + MP_HANDS_VERSION);
  console.log('ThreeJs: r' + REVISION);
}

tfjsWasm.setWasmPaths(`https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${TF_WASM_VERSION}/dist/`);

let detector;
let rafId;

async function createDetector() {
  switch (STATE.model) {
    case handdetection.SupportedModels.MediaPipeHands:
      const runtime = STATE.backend.split('-')[0];
      if (runtime === 'mediapipe') {
        return handdetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type,
          maxHands: STATE.modelConfig.maxNumHands,
          solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/hands@${MP_HANDS_VERSION}`,
        });
      } else if (runtime === 'tfjs') {
        return handdetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type,
          maxHands: STATE.modelConfig.maxNumHands,
        });
      }
  }
}

async function checkGuiUpdate() {
  if (STATE.isModelChanged || STATE.isFlagChanged || STATE.isBackendChanged) {
    STATE.isModelChanged = true;

    window.cancelAnimationFrame(rafId);

    if (detector != null) {
      detector.dispose();
    }

    if (STATE.isFlagChanged || STATE.isBackendChanged) {
      await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    }

    try {
      detector = await createDetector(STATE.model);
    } catch (error) {
      detector = null;
      LogsManager.logError(error);
    }

    STATE.isFlagChanged = false;
    STATE.isBackendChanged = false;
    STATE.isModelChanged = false;
  }
}

/**
 * Reset the target backend.
 *
 * @param backendName The name of the backend to be reset.
 */
async function resetBackend(backendName) {
  const ENGINE = tf.engine();
  if (!(backendName in ENGINE.registryFactory)) {
    throw new Error(`${backendName} backend is not registed.`);
  }

  if (backendName in ENGINE.registry) {
    const backendFactory = tf.findBackendFactory(backendName);
    tf.removeBackend(backendName);
    tf.registerBackend(backendName, backendFactory);
  }

  await tf.setBackend(backendName);
}

/**
 * Set environment flags.
 *
 * This is a wrapper function of `tf.env().setFlags()` to constrain users to
 * only set tunable flags (the keys of `TUNABLE_FLAG_TYPE_MAP`).
 *
 * ```js
 * const flagConfig = {
 *        WEBGL_PACK: false,
 *      };
 * await setEnvFlags(flagConfig);
 *
 * console.log(tf.env().getBool('WEBGL_PACK')); // false
 * console.log(tf.env().getBool('WEBGL_PACK_BINARY_OPERATIONS')); // false
 * ```
 *
 * @param flagConfig An object to store flag-value pairs.
 */
export async function setBackendAndEnvFlags(flagConfig, backend) {
  if (flagConfig == null) {
    return;
  } else if (typeof flagConfig !== 'object') {
    throw new Error(
        `An object is expected, while a(n) ${typeof flagConfig} is found.`);
  }

  // Check the validation of flags and values.
  for (const flag in flagConfig) {
    // TODO: check whether flag can be set as flagConfig[flag].
    if (!(flag in TUNABLE_FLAG_VALUE_RANGE_MAP)) {
      throw new Error(`${flag} is not a tunable or valid environment flag.`);
    }
    if (TUNABLE_FLAG_VALUE_RANGE_MAP[flag].indexOf(flagConfig[flag]) === -1) {
      throw new Error(
          `${flag} value is expected to be in the range [${
              TUNABLE_FLAG_VALUE_RANGE_MAP[flag]}], while ${flagConfig[flag]}` +
          ' is found.');
    }
  }

  tf.env().setFlags(flagConfig);

  const [runtime, $backend] = backend.split('-');

  if (runtime === 'tfjs') {
    await resetBackend($backend);
  }
}

const MIXINS = [
  HasEventsMixin,
];

/**
 * Singleton centralizing the core of the hands tracking technology
 *
 * May emit those events :
 * - tracking-data-received   = after hand tracking data has been received
 */
class CoreTracking {
  constructor() {
    MixinsComposer.construct(this, MIXINS);

    this.trackingLoop = createUpdatesLoop(this._tickTracking.bind(this), 30);

    // pause loop when not visible
    this.trackingLoop.isPaused = !ViewportManager.viewportVisible;
    ViewportManager.on('visibility-changed', (visible) => {
      this.trackingLoop.isPaused = !visible;
    });
  }

  async initialize() {
    await setupDatGui();
    await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    detector = await createDetector();
  }

  startTrackingLoop() {
    let promise = null;
    const loop = () => {
      promise = this.trackingLoop.loop(performance.now());

      if (promise) {
        promise.then(goAgain)
      } else {
        goAgain();
      }
    };
    const goAgain = () => {
      rafId = requestAnimationFrame(loop);
    };
    loop();

    if (CONFIG.DEBUG_FPS_STATS) {
      StatsPanel.setupHandTrackingPanel();
    }
  }

  async _tickTracking() {
    FpsManager.handTrackingFpsTracker.begin();

    await checkGuiUpdate();

    if (!STATE.isModelChanged && UserCamera.isReady()) {
      let handsData = null;

      // Detector can be null if initialization failed (for example when loading
      // from a URL that does not exist).
      if (detector != null) {
        // Detectors can throw errors, for example when using custom URLs that
        // contain a model that doesn't provide the expected output.
        try {
          handsData = await detector.estimateHands( UserCamera.videoPlayer.getVideoElem(), {flipHorizontal: false});
        } catch (error) {
          detector.dispose();
          detector = null;
          LogsManager.logError(error);
        }
      }

      // The null check makes sure the UI is not in the middle of changing to a
      // different model. If during model change, the result is from an old model,
      // which shouldn't be rendered.
      if (handsData && !STATE.isModelChanged && !window.paused) {
        this.emit('tracking-data-received', handsData);
      }
    }

    FpsManager.handTrackingFpsTracker.end();
  }
}

MixinsComposer.extend(CoreTracking).with(...MIXINS);

export default new CoreTracking();

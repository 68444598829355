import {Vector3} from 'three';

/**
 * Singleton class to keep track of where is situated the palm center of hands rigs
 */
class PalmCentersManager {
  constructor() {
    this._currentPalmCentersMap = new Map();
    this._currentIndexFingerPosMap = new Map();
  }

  _setMapValue(map, handRig, centerX = 0, centerY = 0, centerZ = 0) {
    const value = map.get(handRig);
    if (value) {
      value.set(centerX, centerY, centerZ);
    } else {
      map.set(handRig, new Vector3(centerX, centerY, centerZ));
    }
  }

  setCurrentPalmCenter(handRig, centerX, centerY, centerZ) {
    this._setMapValue(this._currentPalmCentersMap, handRig, centerX, centerY, centerZ);
  }

  setCurrentIndexPosition(handRig, centerX, centerY, centerZ) {
    this._setMapValue(this._currentIndexFingerPosMap, handRig, centerX, centerY, centerZ);
  }

  getPalmCenters() {
    return this._currentPalmCentersMap;
  }

  getIndexFingersPositions() {
    return this._currentIndexFingerPosMap;
  }
}

export default new PalmCentersManager();

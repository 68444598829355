import {
  // MeshToonMaterial,
  FrontSide,
  DoubleSide,
} from 'three';

import handModelPath from '../assets/hand2.glb';
// import handModelPath from '../assets/hand2-short.glb';

import CONFIG from '../config.js';

import AssetsManager from '../managers/AssetsManager.js';

import TrackingDataSanitizer from '../tracking/TrackingDataSanitizer.js';
import ViewportTranslate2d from '../tracking/ViewportTranslate2d.js';
import TwoDimensionalDataMixer from '../tracking/TwoDimensionalDataMixer.js';
import TrackingDataIK from '../tracking/TrackingDataIK.js';
import KalmanFilterSmoothing from '../tracking/KalmanFilterSmoothing.js';
import DepthEstimator from '../tracking/DepthEstimator.js';
import HandPoseDetector from '../tracking/HandPoseDetector.js';
import RiggingStrategy from './RiggingStrategy.js';
import RiggingProfile from './RiggingProfile.js';
import PointsHelper from '../threejs/view/PointsHelper.js';
import SkeletonHelper from '../threejs/view/SkeletonHelper.js';

// import QuickHideStrategy from './visibility/QuickHideStrategy.js';
import FadeHideStrategy from './visibility/FadeHideStrategy.js';

import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'

const MIXINS = [
  HasEventsMixin,
];

let RIGGING_PROFILE = null;

/**
 * Encapsulate a single hand rig, with everything needed to make it work
 *
 * May emit those events :
 * - tracking-input-sanitized       = after incoming tracking data has been cleaned
 * - visibility-changed             = after the visibility has changed
 */
class HandRig {
  constructor(scene, isBlack = false) {
    MixinsComposer.construct(this, MIXINS);

    this.isVisible = true;

    this._isReady = false;
    this._isInverted = false; // right or left handed
    this._scene = scene;
    this._armature = null;
    this._skinnedMesh = null;
    this._bones = {};
    this._bonesArr = [];
    this._pointsHelperView = null;
    this._skinTone = isBlack ? 0x231713 : 0x9f6757;

    this._sanitizingStrategy = new TrackingDataSanitizer();
    this._translateStrategy = new ViewportTranslate2d(this);
    this._experimental2dMixer = new TwoDimensionalDataMixer(this);
    this._inverseKinematicsStrategy = null;
    this._smoothingStrategy = new KalmanFilterSmoothing();
    this._depthEstimator = new DepthEstimator(this);
    this._handPoseDetector = new HandPoseDetector(this);
    this._riggingStrategy = new RiggingStrategy(this);

    this.riggingProfile = RIGGING_PROFILE || null;

    // this._visibilityStrategy = new QuickHideStrategy(this);
    this._visibilityStrategy = new FadeHideStrategy(this);

    this._visibilityStrategy.on('visibility-changed', (visible) => {
      this.isVisible = visible;
      this.emit('visibility-changed', visible);
    });

    // load the hand model
    AssetsManager.loadGltfFile(handModelPath).then(this._onModelLoaded.bind(this));
  }

  getAllBones() {
    return this._bonesArr;
  }

  handleTrackingInput(handData) {
    if (!this._isReady || !handData.keypoints3D.length || handData.score < this._minimumConfidenceScore) {
      return;
    }

    const experimentalMode = CONFIG.EXPERIMENTAL_TRACKING /*&& handData.handedness === 'Left'*/;

    this._isInverted = CONFIG.FRONT_FACING_CAMERA ? handData.handedness === 'Left' : handData.handedness === 'Right';

    // sanitize data
    if (CONFIG.SANITIZE_TRACKING_DATA) {
      this._sanitizingStrategy.handleTrackingInput(handData);
    }

    if (experimentalMode) {
      this._experimental2dMixer.handleTrackingInput(handData);
    }
    else {
      // 2d translate
      if (CONFIG.HAND_TRANSLATED) {
        this._translateStrategy.handleTrackingInput(handData);
      }
    }

    // inverse kinematics
    if (CONFIG.IK_TRACKING_DATA && this._inverseKinematicsStrategy) {
      this._inverseKinematicsStrategy.handleTrackingInput(handData);
    }

    // smooth data
    if (CONFIG.SMOOTH_TRACKING_DATA) {
      this._smoothingStrategy.handleTrackingInput(handData);
    }

    // depth estimation
    if (CONFIG.DEPTH_ESTIMATION) {
      this._depthEstimator.handleTrackingInput(handData);
    }

    this._lastHandData = handData;
    this.emit('tracking-input-sanitized', handData);

    // pose detection
    if (CONFIG.POSE_DETECTION) {
      this._handPoseDetector.handleTrackingInput(handData);
    }

    // rigging
    this._riggingStrategy.handleTrackingInput(handData);

    // visibility update
    this._visibilityStrategy.tickVisible();

    // update points visual
    if (this._pointsHelperView) {
      this._pointsHelperView.updateFromKeypoints3D( handData.keypoints3D );
    }
  }

  handleInputLost() {
    if (!this._isReady) {
      return;
    }

    this._handPoseDetector.tickInvisible();

    this._visibilityStrategy.tickInvisible();
  }

  isVisible() {
    return this._armature.visible;
  }

  _initHelpers() {
    if (CONFIG.DEBUG_POINTS_HELPERS) {
      this._pointsHelperView = new PointsHelper();
      this._addHelper(this._pointsHelperView);
    }
    if (CONFIG.DEBUG_SKELETON_HELPERS) {
      // note: pass the armature in constructor to get the bones properly,
      // then set root to make sure matrices calculations works properly after
      const skeletonHelper = new SkeletonHelper(this._armature);
      skeletonHelper.root = this._skinnedMesh;

      this._addHelper(skeletonHelper);
    }
  }

  _addHelper(helper) {
    // this._armature.add(helper);
    this._scene.mainContainer.add(helper);

    const copyCatMaterial = this._skinnedMesh.material;
    const copyCatArmature = this._armature;

    // make sure helpers materials keep the same visibility has the skinned mesh,
    // because otherwise it would stay opaqye when hand is transparent
    Object.defineProperties(helper.material, {
      transparent: {
        get() { return copyCatMaterial.transparent; },
      },
      opacity: {
        get() { return copyCatMaterial.opacity; },
      },
    });

    Object.defineProperties(helper, {
      // apply the same visibility that visibility strategy sets
      visible: {
        get() { return copyCatArmature.visible; },
      },
      // apply the same scaling we apply on the armature to handle Left handedness
      scale: {
        get() { return copyCatArmature.scale; },
      },
    });
  }

  _onModelLoaded(result) {
    this._armature = result.scene.getObjectByName('Armature');
    this._skinnedMesh = result.scene.getObjectByName('Mesh');

    // get bones
    this._armature.traverse((obj) => {
      if (obj.isBone) {
        this._bones[obj.name] = obj;
        this._bonesArr.push(obj);
      }
    });

    if (this._bones.wrist) {
      // @TODO this assumes that every hands rig will be from the same model during that session !
      if (!RIGGING_PROFILE) {
        RIGGING_PROFILE = new RiggingProfile(this._bones.wrist);
      }
      if (!this.riggingProfile) {
        this.riggingProfile = RIGGING_PROFILE;
      }

      if (CONFIG.IK_TRACKING_DATA) {
        this._inverseKinematicsStrategy = new TrackingDataIK(RIGGING_PROFILE);
      }
    }

    this._armature.visible = false;

    // handle skinned mesh
    this._skinnedMesh.castShadow = CONFIG.SHADOW_CASTING_ON;
    this._skinnedMesh.receiveShadow = CONFIG.SHADOW_CASTING_ON;

    // this._skinnedMesh.material = new MeshToonMaterial({
    //   color: 0xffffff,
    //   wireframe: true,
    // });

    this._skinnedMesh.material.color.set(this._skinTone);
    this._skinnedMesh.material.side = FrontSide;
    this._skinnedMesh.material.shadowSide = DoubleSide;
    this._skinnedMesh.material.dithering = true;
    // this._skinnedMesh.renderOrder = 99999;

    this._riggingStrategy.initializeAfterReady();
    this._visibilityStrategy.initializeAfterReady();
    this._initHelpers();

    this._scene.handsContainer.add(this._armature);

    this._isReady = true;
  }
}

MixinsComposer.extend(HandRig).with(...MIXINS);

HandRig.prototype._minimumConfidenceScore = 0.75;

export default HandRig;

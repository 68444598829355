/**
 * Add events listening and dispatching functionality
 * Code greatly inspired of : https://github.com/mrdoob/eventdispatcher.js/
 */
export default {

  on( eventName, callback ) {
    if ( this._eventsListeners === undefined ) this._eventsListeners = {};

    const listeners = this._eventsListeners;

    if ( listeners[ eventName ] === undefined ) {
      listeners[ eventName ] = [];
    }

    if ( listeners[ eventName ].indexOf( callback ) === - 1 ) {
      listeners[ eventName ].push( callback );
    }

    return {
      off: this._off.bind(this, eventName, callback)
    };
  },

  _off( eventName, callback ) {
    if ( this._eventsListeners === undefined ) return;

    const listeners = this._eventsListeners;
    const listenerArray = listeners[ eventName ];

    if ( listenerArray !== undefined ) {
      const index = listenerArray.indexOf( callback );

      if ( index !== - 1 ) {
        listenerArray.splice( index, 1 );
      }
    }
  },

  once( eventName, callback ) {
    const listener = this.on(eventName, (...data) => {
      callback(...data);
      listener.off();
    });

    return listener;
  },

  emit( eventName, ...data ) {
    if ( this._eventsListeners === undefined ) return;

    const listeners = this._eventsListeners;
    const listenerArray = listeners[ eventName ];

    if ( listenerArray !== undefined ) {
      // Make a copy, in case listeners are removed while iterating.
      const array = listenerArray.slice( 0 );

      for ( let i = 0, l = array.length; i < l; i ++ ) {
        array[ i ].call( this, ...data );
      }
    }
  },

};

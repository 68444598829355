import {Vector3} from 'three';

const _vecA = new Vector3();
const _vecB = new Vector3();

/**
 * Abstract class with abstract methods to simplify tracking data tweaking
 */
class AbstractTrackingDataTweaker {

  handleTrackingInput(handData) {
    // ...to overwrite
  }

  _getDistanceBetweenPoints(pointA, pointB) {
    _vecA.copy(pointA);
    _vecB.copy(pointB);

    return _vecA.distanceTo(_vecB);
  }

  _getDirectionBetweenPoints(pointA, pointB) {
    _vecA.copy(pointA);
    _vecB.copy(pointB);

    return _vecA.sub(_vecB).normalize();
  }

  _getCenterFromPoints(points) {
    const totalPoints = points.length;
    const center = {x: 0, y: 0, z: 0};

    let point;
    for (let i = 0; i < totalPoints; i++) {
      point = points[i];

      center.x += point.x;
      center.y += point.y;
      center.z += point.z;
    }

    center.x /= totalPoints;
    center.y /= totalPoints;
    center.z /= totalPoints;

    return center;
  }

  _movePointInDirection(point, direction, distance) {
    point.x += distance * direction.x;
    point.y += distance * direction.y;
    point.z += distance * direction.z;
  }
}

export default AbstractTrackingDataTweaker;

/**
 * Add functionality of collecting events listeners that should be removed when the host is disposed
 */
export default {
  constructor() {
    this._lifetimeEventsListeners = [];
  },

  registerLifetimeListeners(...listeners) {
    this._lifetimeEventsListeners.push(...listeners);
  },

  clearLifetimeListeners() {
    while(this._lifetimeEventsListeners.length) {
      this._lifetimeEventsListeners.pop().off();
    }
  },
}

import MixinsComposer from '../../utils/MixinsComposer.js'
import HasEventsMixin from '../../mixins/HasEvents.js'

const MIXINS = [
  HasEventsMixin,
];

/**
 * Implement a mechanism to hide un-tracked rig very quickly
 *
 * May emit those events :
 * - visibility-changed     = after the visibility has changed
 */
class AbstractVisibilityStrategy {
  constructor(handRig) {
    MixinsComposer.construct(this, MIXINS);

    this._handRig = handRig;
    this._armature = null;
    this._currentlyVisible = true;
  }

  initializeAfterReady() {
    this._armature = this._handRig._armature;
  }

  tickVisible() {
    if (!this._currentlyVisible) {
      this._setCurrentVisibility(true);
    }
  }

  tickInvisible() {
    if (this._currentlyVisible) {
      this._setCurrentVisibility(false);
    }
  }

  _setCurrentVisibility(visible) {
    this._currentlyVisible = visible;
    this.emit('visibility-changed', visible);
  }
}

MixinsComposer.extend(AbstractVisibilityStrategy).with(...MIXINS);

export default AbstractVisibilityStrategy;

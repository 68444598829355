import {Vector2} from 'three';

import CONFIG from '../config.js';
import {posFromCenteredToThreejs} from '../helpers.js';
import AbstractTrackingDataTweaker from './AbstractTrackingDataTweaker.js';

/**
 * Takes incoming hand tracking data and translate 3D points in the viewport according to 2D positions
 */
class ViewportTranslate2d extends AbstractTrackingDataTweaker {

  constructor(handRig) {
    super();

    this._handRig = handRig;
    this._translationVector2D = new Vector2(0, 0);
  }

  /**
  * Handle incoming data
  */
  handleTrackingInput(handData) {
    if (!handData.keypoints) {
      return;
    }

    this._updatePalmCenter(handData.keypoints);
    this._adaptTranslationVector(handData.keypoints);

    let point;
    for (let i = 0; i < handData.keypoints3D.length; i++) {
      point = handData.keypoints3D[i];
      point.x += this._translationVector2D.x;
      point.y += this._translationVector2D.y;
    }
  }

  _updatePalmCenter(keypoints) {
    this._translationVector2D.set(0, 0);

    // calculate average/center from at least 3 points
    let keypoint;
    for (let i = 0; i < keypoints.length; i++) {
      keypoint = keypoints[i];
      if (this.bonesUsedForPalmCalculations.indexOf(keypoint.name) !== -1) {
        this._translationVector2D.add( keypoint );
      }
    }
    this._translationVector2D.divideScalar(this.bonesUsedForPalmCalculations.length);
  }

  _adaptTranslationVector(keypoints) {
    // adapt to our needs
    posFromCenteredToThreejs(this._translationVector2D);

    // compensate perspective in the movement range
    const zFactor = this._handRig._depthEstimator.zFactor;
    const perspectiveCompensation = this._handRig._scene._maximumPerspectiveCompensation * 3;

    let perspectiveFactor = perspectiveCompensation * zFactor;
    if (!CONFIG.FRONT_FACING_CAMERA) {
      perspectiveFactor = perspectiveCompensation - perspectiveFactor;
    }
    perspectiveFactor += 1;

    this._translationVector2D.x *= perspectiveFactor;
    this._translationVector2D.y *= perspectiveFactor;

    // compensate hand scale
    if (!CONFIG.LEGACY_CENTERING) {
      const handScaleCompensation = 1 / CONFIG.HAND_LOCAL_SCALE;
      this._translationVector2D.x *= handScaleCompensation;
      this._translationVector2D.y *= handScaleCompensation;
    }

    // @TODO do those manipulations inside TrackingDataSanitizer only ?
    // invert X axis if needed
    if (this._handRig._isInverted) {
      this._translationVector2D.x = - this._translationVector2D.x;
    }
    if (!CONFIG.FRONT_FACING_CAMERA) {
      this._translationVector2D.x = - this._translationVector2D.x;
    }
  }
}

// NOTE: initially I thought the palm of the hand was the center of the hand,
// but proportionally it's not right because the space used by fingers is much longer than below the palm,
// therefore considering the center more near the base of fingers gives a more proportional placement
ViewportTranslate2d.prototype.bonesUsedForPalmCalculations = [
  // 'wrist',
  'index_finger_mcp',
  'pinky_finger_mcp',
];

export default ViewportTranslate2d;

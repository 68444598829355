import Stats from 'three/examples/jsm/libs/stats.module.js';

// import {IS_MOBILE} from '../env.js';
import CONFIG from '../config.js';
import FpsManager from '../managers/FpsManager.js';

/**
 * Singleton used to display FPS and other metrics for debugging
 * NOTE: because Stats is a weird old thing, I can't extend it using a normal Class
 */
const singleton = new Stats();

if (CONFIG.DEBUG_FPS_STATS) {
  // make stats BIGGER
  /*
  if (IS_MOBILE) {
    singleton.domElement.style.transform = 'scale(1.35)';
    singleton.domElement.style.transformOrigin = 'top left';
  }
  */
}

// implement hands tracking FPS panel
singleton.setupHandTrackingPanel = function() {
  const handTrackingFpsPanel = new Stats.Panel('HAND', '#d9927d', '#231713');
  singleton.addPanel(handTrackingFpsPanel);
  singleton.showPanel(singleton.domElement.children.length - 1);

  FpsManager.handTrackingFpsTracker.on('fps-tick', (fps) => {
    handTrackingFpsPanel.update( fps, CONFIG.MAX_TRACKING_FPS );
  });
}

export default singleton;

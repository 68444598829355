import _find from 'lodash/find.js';

import AbstractPersonRig from './AbstractPersonRig.js';
import HandRig from './HandRig.js';

/**
 * Encapsulate the rigging of a person with two hand
 */
class PersonRigTwoHanded extends AbstractPersonRig {
  constructor(scene) {
    super();

    this._rightHand = new HandRig(scene);
    this._leftHand = new HandRig(scene);

    this._lastLeftHandData = null;
    this._lastRightHandData = null;

    this._registerHandRig(this._rightHand);
    this._registerHandRig(this._leftHand);
  }

  getHandRigs() {
    return [this._rightHand, this._leftHand];
  }

  handleHandsTrackingInput(handsData) {
    // left hand
    this._lastLeftHandData = _find(handsData, {handedness: 'Left'});
    if (this._lastLeftHandData) {
      this._leftHand.handleTrackingInput(this._lastLeftHandData);
    } else {
      this._leftHand.handleInputLost();
    }

    // right hand
    this._lastRightHandData = _find(handsData, {handedness: 'Right'});
    if (this._lastRightHandData) {
      this._rightHand.handleTrackingInput(this._lastRightHandData);
    } else {
      this._rightHand.handleInputLost();
    }
  }
}

export default PersonRigTwoHanded;

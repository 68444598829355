import _cloneDeep from 'lodash/cloneDeep.js';

import CONFIG from '../config.js';
import {spreadHand, peaceHand, devilHand, leftHand, crookedHand, palmAwayHand} from '../datas/handsSamples.js';

/**
 * Class that can feed sample hands tracking data to a function
 */
class FakeTrackingDataFeeder {
  constructor(func) {
    this._func = func;
    this._samplesArray = [spreadHand, peaceHand, devilHand, leftHand, crookedHand, palmAwayHand];
    this._samplesIndex = 0;
    this._renderInterval = null;
    this._toggleInterval = null;

    this.start();
  }

  start() {
    if (!this._renderInterval) {
      this._renderInterval = setInterval(this._tickRender.bind(this), 1000 / this._fps);
    }
    if (!this._toggleInterval && CONFIG.QUICK_DEBUG_INTERVAL > 0) {
      this._toggleInterval = setInterval(this._tickToggle.bind(this), CONFIG.QUICK_DEBUG_INTERVAL);
    }
  }

  stop() {
    if (this._renderInterval) {
      clearInterval(this._renderInterval);
    }
    if (this._toggleInterval) {
      clearInterval(this._toggleInterval);
    }
  }

  _getCurrentSample() {
    // note: we must clone data every time because the smoothing strategy is updating the data directly
    return _cloneDeep(this._samplesArray[this._samplesIndex]);
  }

  _tickRender() {
    const data = this._getCurrentSample();

    // attempt to generate 2d positions (that I didn't put in my samples)
    // mostly to improve how it looks with both hands
    if (data.keypoints3D && !data.keypoints) {
      const videoHalfWidth = 640 * 0.5;
      const videoHalfHeight = 480 * 0.5;
      const decalAmount = CONFIG.BOTH_HANDS_ENABLED ? videoHalfWidth * 0.25 : 0;
      const decalDirectional = (data.handedness === 'Left' ? -decalAmount : decalAmount);

      data.keypoints = [];

      let point3D;
      for (let i = 0; i < data.keypoints3D.length; i++) {
        point3D = data.keypoints3D[i];

        data.keypoints.push({
          x: videoHalfWidth + decalDirectional,
          y: videoHalfHeight,
          name: point3D.name,
          score: point3D.score,
        });
      }
    }

    this._func([data]);
  }

  _tickToggle() {
    if (++this._samplesIndex > (this._samplesArray.length - 1)) {
      this._samplesIndex = 0;
    }
  }
}

FakeTrackingDataFeeder.prototype._fps = 30;

export default FakeTrackingDataFeeder;

const MAX_INWARD_BEND_ANGLE = Math.PI * 0.6;

/**
 * Singleton class that can enforce angle constraints on bones
 */
class RotationConstraintsIK {

  // @TODO I would like to apply that on data, but I would need to calculate a bunch more rotations... so I do it here instead,
  // but maybe I could calculate every rotation before (in an other step) and just apply them to every bones on the rig here ?
  enforceFingerConstraints(bones, isThumb = false) {
    let bone;
    for (let i = 0; i < bones.length; i++) {
      bone = bones[i];

      // fingers can't bend sideways (well, the thumb can)
      if (!isThumb) {
        bone.rotation.z = 0;
      }

      // fingers can't bend backward
      bone.rotation.x = Math.max(-0.075, bone.rotation.x);

      this._enforceGlobalFingerConstraint(bone);
    }
  }

  enforceMetacarpalsConstraints(bones) {
    let bone;
    for (let i = 0; i < bones.length; i++) {
      bone = bones[i];

      // prevent unnatural sideways spread (happens mostly when tracking back side of hand)
      bone.rotation.z = Math.min(0.5, Math.max(-0.5, bone.rotation.z));

      // metacarpals can't bend backward
      bone.rotation.x = Math.max(-0.35, bone.rotation.x);

      this._enforceGlobalFingerConstraint(bone);
    }
  }

  _enforceGlobalFingerConstraint(bone) {
    // fingers can't rotate on their vertical axis
    bone.rotation.y = 0;

    // limit inward bend
    bone.rotation.x = Math.min(MAX_INWARD_BEND_ANGLE, bone.rotation.x);
  }
}

export default new RotationConstraintsIK();

// 🖐
export const spreadHand = {
  handedness: 'Right',
  score: 1,
  keypoints3D: [
    {x: -0.0140577033162117, y: 0.07900748401880264, z: 0.052093505859375, score: undefined, name: 'wrist'},
    {x: -0.036645036190748215, y: 0.055636052042245865, z: 0.021820068359375, score: undefined, name: 'thumb_cmc'},
    {x: -0.051884207874536514, y: 0.03956662490963936, z: -0.001129150390625, score: undefined, name: 'thumb_mcp'},
    {x: -0.0626007691025734, y: 0.018016749992966652, z: -0.029693603515625, score: undefined, name: 'thumb_ip'},
    {x: -0.06934686005115509, y: -0.0021450212225317955, z: -0.0455322265625, score: undefined, name: 'thumb_tip'},
    {x: -0.01732752099633217, y: -0.011463881470263004, z: -0.0074462890625, score: undefined, name: 'index_finger_mcp'},
    {x: -0.014247969724237919, y: -0.03783843293786049, z: -0.018218994140625, score: undefined, name: 'index_finger_pip'},
    {x: -0.010810401290655136, y: -0.0508350133895874, z: -0.0338134765625, score: undefined, name: 'index_finger_dip'},
    {x: -0.006132615264505148, y: -0.060149114578962326, z: -0.06781005859375, score: undefined, name: 'index_finger_tip'},
    {x: -0.0007187756709754467, y: -0.00826144777238369, z: -0.00003999471664428711, score: undefined, name: 'middle_finger_mcp'},
    {x: 0.009872136637568474, y: -0.036856360733509064, z: -0.00946044921875, score: undefined, name: 'middle_finger_pip'},
    {x: 0.016507400199770927, y: -0.05492977052927017, z: -0.0277099609375, score: undefined, name: 'middle_finger_dip'},
    {x: 0.02437272109091282, y: -0.06551848351955414, z: -0.059906005859375, score: undefined, name: 'middle_finger_tip'},
    {x: 0.012482170946896076, y: 0.00680188974365592, z: 0.006221771240234375, score: undefined, name: 'ring_finger_mcp'},
    {x: 0.026318181306123734, y: -0.021999862045049667, z: -0.0062255859375, score: undefined, name: 'ring_finger_pip'},
    {x: 0.03649003058671951, y: -0.036236729472875595, z: -0.0290985107421875, score: undefined, name: 'ring_finger_dip'},
    {x: 0.04497372359037399, y: -0.04771411418914795, z: -0.055419921875, score: undefined, name: 'ring_finger_tip'},
    {x: 0.022248806431889534, y: 0.026435211300849915, z: 0.00611114501953125, score: undefined, name: 'pinky_finger_mcp'},
    {x: 0.03890535235404968, y: 0.007794931530952454, z: 0.001354217529296875, score: undefined, name: 'pinky_finger_pip'},
    {x: 0.05190885812044144, y: 0.0010905954986810684, z: -0.01558685302734375, score: undefined, name: 'pinky_finger_dip'},
    {x: 0.0637165755033493, y: 0.001402921974658966, z: -0.03521728515625, score: undefined, name: 'pinky_finger_tip'},
  ],
};

// ✌
export const peaceHand = {
  handedness: 'Right',
  score: 1,
  keypoints3D: [
    {x: -0.011732279323041439, y: 0.08884044736623764, z: 0.00536346435546875, score: undefined, name: 'wrist'},
    {x: -0.03165151923894882, y: 0.06197133660316467, z: -0.0100860595703125, score: undefined, name: 'thumb_cmc'},
    {x: -0.03039073385298252, y: 0.037067677825689316, z: -0.02679443359375, score: undefined, name: 'thumb_mcp'},
    {x: -0.010687507688999176, y: 0.010509992018342018, z: -0.047698974609375, score: undefined, name: 'thumb_ip'},
    {x: 0.013402639888226986, y: -0.00327174155972898, z: -0.0489501953125, score: undefined, name: 'thumb_tip'},
    {x: -0.022862259298563004, y: -0.009097970090806484, z: -0.005184173583984375, score: undefined, name: 'index_finger_mcp'},
    {x: -0.024289589375257492, y: -0.04036650434136391, z: -0.0085296630859375, score: undefined, name: 'index_finger_pip'},
    {x: -0.021484192460775375, y: -0.06152985990047455, z: -0.0160980224609375, score: undefined, name: 'index_finger_dip'},
    {x: -0.01852296106517315, y: -0.07993058115243912, z: -0.035888671875, score: undefined, name: 'index_finger_tip'},
    {x: -0.0020401435904204845, y: -0.005370637401938438, z: 0.0011167526245117188, score: undefined, name: 'middle_finger_mcp'},
    {x: 0.009793070144951344, y: -0.04098295047879219, z: 0.0031528472900390625, score: undefined, name: 'middle_finger_pip'},
    {x: 0.01695387251675129, y: -0.06543979793787003, z: -0.007213592529296875, score: undefined, name: 'middle_finger_dip'},
    {x: 0.02246306836605072, y: -0.08281766623258591, z: -0.0284423828125, score: undefined, name: 'middle_finger_tip'},
    {x: 0.016706669703125954, y: 0.004314650781452656, z: 0.004245758056640625, score: undefined, name: 'ring_finger_mcp'},
    {x: 0.020839473232626915, y: -0.010955051518976688, z: -0.0183258056640625, score: undefined, name: 'ring_finger_pip'},
    {x: 0.011122239753603935, y: 0.007520165294408798, z: -0.033294677734375, score: undefined, name: 'ring_finger_dip'},
    {x: 0.001878974842838943, y: 0.029680995270609856, z: -0.035552978515625, score: undefined, name: 'ring_finger_tip'},
    {x: 0.028035717085003853, y: 0.023760294541716576, z: 0.00015628337860107422, score: undefined, name: 'pinky_finger_mcp'},
    {x: 0.027536476030945778, y: 0.011746890842914581, z: -0.01904296875, score: undefined, name: 'pinky_finger_pip'},
    {x: 0.017286835238337517, y: 0.026085402816534042, z: -0.031829833984375, score: undefined, name: 'pinky_finger_dip'},
    {x: 0.011483602225780487, y: 0.046441156417131424, z: -0.0245208740234375, score: undefined, name: 'pinky_finger_tip'},
  ],
};

// 🤘
export const devilHand = {
  handedness: 'Right',
  score: 1,
  keypoints3D: [
    {x: 0.0037255450151860714, y: 0.0887664183974266, z: 0.041473388671875, score: undefined, name: 'wrist'},
    {x: -0.018908191472291946, y: 0.067861407995224, z: 0.008087158203125, score: undefined, name: 'thumb_cmc'},
    {x: -0.02043517678976059, y: 0.04545058682560921, z: -0.0188446044921875, score: undefined, name: 'thumb_mcp'},
    {x: -0.002885913709178567, y: 0.02070624753832817, z: -0.053070068359375, score: undefined, name: 'thumb_ip'},
    {x: 0.019250761717557907, y: 0.003805681364610791, z: -0.0550537109375, score: undefined, name: 'thumb_tip'},
    {x: -0.02818428911268711, y: -0.0033200974576175213, z: -0.01361083984375, score: undefined, name: 'index_finger_mcp'},
    {x: -0.030151763930916786, y: -0.029331857338547707, z: -0.0246124267578125, score: undefined, name: 'index_finger_pip'},
    {x: -0.030454523861408234, y: -0.05090859904885292, z: -0.035491943359375, score: undefined, name: 'index_finger_dip'},
    {x: -0.02945866994559765, y: -0.06827838718891144, z: -0.057159423828125, score: undefined, name: 'index_finger_tip'},
    {x: -0.003720837878063321, y: -0.0061319745145738125, z: -0.0021152496337890625, score: undefined, name: 'middle_finger_mcp'},
    {x: 0.007968543097376823, y: -0.019621631130576134, z: -0.0300445556640625, score: undefined, name: 'middle_finger_pip'},
    {x: 0.009406838566064835, y: -0.0003863934543915093, z: -0.041839599609375, score: undefined, name: 'middle_finger_dip'},
    {x: 0.005988170392811298, y: 0.014048178680241108, z: -0.04022216796875, score: undefined, name: 'middle_finger_tip'},
    {x: 0.021434728056192398, y: 0.00017002131789922714, z: 0.01032257080078125, score: undefined, name: 'ring_finger_mcp'},
    {x: 0.02698909305036068, y: -0.0068275281228125095, z: -0.0195770263671875, score: undefined, name: 'ring_finger_pip'},
    {x: 0.02176397480070591, y: 0.01555415615439415, z: -0.0284576416015625, score: undefined, name: 'ring_finger_dip'},
    {x: 0.016292905434966087, y: 0.034040167927742004, z: -0.0238494873046875, score: undefined, name: 'ring_finger_tip'},
    {x: 0.03856368362903595, y: 0.012392301112413406, z: 0.0164642333984375, score: undefined, name: 'pinky_finger_mcp'},
    {x: 0.04400821030139923, y: -0.01020645722746849, z: 0.01003265380859375, score: undefined, name: 'pinky_finger_pip'},
    {x: 0.05677955970168114, y: -0.025438740849494934, z: -0.004058837890625, score: undefined, name: 'pinky_finger_dip'},
    {x: 0.06565086543560028, y: -0.042895667254924774, z: -0.01427459716796875, score: undefined, name: 'pinky_finger_tip'},
  ],
};

// 👋
export const crookedHand = {
  handedness: 'Left',
  score: 1,
  keypoints3D: [
    {x: -0.019349778071045876, y: 0.0924268439412117, z: -0.0233306884765625, score: undefined, name: 'wrist'},
    {x: -0.02896372601389885, y: 0.06379314512014389, z: 0.00505828857421875, score: undefined, name: 'thumb_cmc'},
    {x: -0.0328705757856369, y: 0.038599081337451935, z: 0.024658203125, score: undefined, name: 'thumb_mcp'},
    {x: -0.035928498953580856, y: 0.010389870963990688, z: 0.046966552734375, score: undefined, name: 'thumb_ip'},
    {x: -0.03893652558326721, y: -0.00860742200165987, z: 0.0718994140625, score: undefined, name: 'thumb_tip'},
    {x: -0.006492074113339186, y: -0.00505911186337471, z: 0.02703857421875, score: undefined, name: 'index_finger_mcp'},
    {x: 0.001477912999689579, y: -0.0351502001285553, z: 0.033233642578125, score: undefined, name: 'index_finger_pip'},
    {x: 0.0034394795075058937, y: -0.0565115250647068, z: 0.0276947021484375, score: undefined, name: 'index_finger_dip'},
    {x: 0.004772201180458069, y: -0.07774728536605835, z: 0.01456451416015625, score: undefined, name: 'index_finger_tip'},
    {x: 0.0025560944341123104, y: -0.005498617421835661, z: 0.005435943603515625, score: undefined, name: 'middle_finger_mcp'},
    {x: 0.01006740890443325, y: -0.03875782713294029, z: 0.01049041748046875, score: undefined, name: 'middle_finger_pip'},
    {x: 0.014901584014296532, y: -0.06704448908567429, z: 0.00975799560546875, score: undefined, name: 'middle_finger_dip'},
    {x: 0.017931118607521057, y: -0.09269210696220398, z: 0.00034737586975097656, score: undefined, name: 'middle_finger_tip'},
    {x: 0.005105641670525074, y: 0.0025016230065375566, z: -0.0178070068359375, score: undefined, name: 'ring_finger_mcp'},
    {x: 0.010514197871088982, y: -0.028874268755316734, z: -0.01666259765625, score: undefined, name: 'ring_finger_pip'},
    {x: 0.012184438295662403, y: -0.05482487007975578, z: -0.021148681640625, score: undefined, name: 'ring_finger_dip'},
    {x: 0.012277258560061455, y: -0.07808838784694672, z: -0.0234222412109375, score: undefined, name: 'ring_finger_tip'},
    {x: 0.004589020740240812, y: 0.016412854194641113, z: -0.040924072265625, score: undefined, name: 'pinky_finger_mcp'},
    {x: 0.008195060305297375, y: -0.008928501047194004, z: -0.042083740234375, score: undefined, name: 'pinky_finger_pip'},
    {x: 0.010769205167889595, y: -0.032799359411001205, z: -0.047149658203125, score: undefined, name: 'pinky_finger_dip'},
    {x: 0.010574962943792343, y: -0.05073961615562439, z: -0.04840087890625, score: undefined, name: 'pinky_finger_tip'},
  ],
};

// 👋
export const leftHand = {
  handedness: 'Left',
  score: 1,
  keypoints3D: [
    {x: 0.000618885038420558, y: 0.07569006830453873, z: 0.042816162109375, score: undefined, name: 'wrist'},
    {x: 0.02787698805332184, y: 0.057188939303159714, z: 0.0207977294921875, score: undefined, name: 'thumb_cmc'},
    {x: 0.04698643088340759, y: 0.046847451478242874, z: 0.0029430389404296875, score: undefined, name: 'thumb_mcp'},
    {x: 0.0644734725356102, y: 0.031141141429543495, z: -0.0205230712890625, score: undefined, name: 'thumb_ip'},
    {x: 0.07731982320547104, y: 0.019283808767795563, z: -0.0322265625, score: undefined, name: 'thumb_tip'},
    {x: 0.023397749289870262, y: -0.00454735616222024, z: -0.0028228759765625, score: undefined, name: 'index_finger_mcp'},
    {x: 0.029372351244091988, y: -0.027540959417819977, z: -0.0134735107421875, score: undefined, name: 'index_finger_pip'},
    {x: 0.03447895497083664, y: -0.04333663359284401, z: -0.0272369384765625, score: undefined, name: 'index_finger_dip'},
    {x: 0.041609473526477814, y: -0.058096397668123245, z: -0.05517578125, score: undefined, name: 'index_finger_tip'},
    {x: 0.0037435831036418676, y: -0.0042704320512712, z: 0.000004887580871582031, score: undefined, name: 'middle_finger_mcp'},
    {x: 0.0016379656735807657, y: -0.037463635206222534, z: -0.007251739501953125, score: undefined, name: 'middle_finger_pip'},
    {x: 0.0027191091794520617, y: -0.06068896874785423, z: -0.0253753662109375, score: undefined, name: 'middle_finger_dip'},
    {x: 0.0032940662931650877, y: -0.07480558753013611, z: -0.05462646484375, score: undefined, name: 'middle_finger_tip'},
    {x: -0.015515285544097424, y: 0.0003061080351471901, z: 0.00275421142578125, score: undefined, name: 'ring_finger_mcp'},
    {x: -0.022815197706222534, y: -0.028323901817202568, z: -0.004360198974609375, score: undefined, name: 'ring_finger_pip'},
    {x: -0.027088221162557602, y: -0.0496370904147625, z: -0.0229339599609375, score: undefined, name: 'ring_finger_dip'},
    {x: -0.02949507161974907, y: -0.06237070634961128, z: -0.048309326171875, score: undefined, name: 'ring_finger_tip'},
    {x: -0.0292636901140213, y: 0.014038236811757088, z: 0.002197265625, score: undefined, name: 'pinky_finger_mcp'},
    {x: -0.042219650000333786, y: -0.006554881110787392, z: -0.0013246536254882812, score: undefined, name: 'pinky_finger_pip'},
    {x: -0.05186283588409424, y: -0.021945178508758545, z: -0.01373291015625, score: undefined, name: 'pinky_finger_dip'},
    {x: -0.05712628364562988, y: -0.03284086287021637, z: -0.033660888671875, score: undefined, name: 'pinky_finger_tip'},
  ],
};

// 🤚
export const palmAwayHand = {
  handedness: 'Left',
  score: 1,
  keypoints3D: [
    {x: 0.007880250923335552, y: 0.057781022042036057, z: 0.051055908203125, score: undefined, name: 'wrist'},
    {x: -0.01294888649135828, y: 0.03511866554617882, z: 0.038421630859375, score: undefined, name: 'thumb_cmc'},
    {x: -0.025820285081863403, y: 0.02202092483639717, z: 0.0281524658203125, score: undefined, name: 'thumb_mcp'},
    {x: -0.04396258667111397, y: 0.00884537398815155, z: 0.006969451904296875, score: undefined, name: 'thumb_ip'},
    {x: -0.06629687547683716, y: 0.0016118199564516544, z: -0.0077972412109375, score: undefined, name: 'thumb_tip'},
    {x: -0.006150688976049423, y: -0.004898808430880308, z: -0.005092620849609375, score: undefined, name: 'index_finger_mcp'},
    {x: -0.01566488668322563, y: -0.023160191252827644, z: -0.0039005279541015625, score: undefined, name: 'index_finger_pip'},
    {x: -0.022455383092164993, y: -0.04145525395870209, z: 0.002582550048828125, score: undefined, name: 'index_finger_dip'},
    {x: -0.02650315687060356, y: -0.058765675872564316, z: 0.0123443603515625, score: undefined, name: 'index_finger_tip'},
    {x: -0.00008215727575588971, y: -0.0012456520926207304, z: -0.005138397216796875, score: undefined, name: 'middle_finger_mcp'},
    {x: -0.0024353130720555782, y: -0.027192464098334312, z: 0.000013172626495361328, score: undefined, name: 'middle_finger_pip'},
    {x: -0.0029285745695233345, y: -0.05474288761615753, z: 0.00939178466796875, score: undefined, name: 'middle_finger_dip'},
    {x: -0.004930189810693264, y: -0.07331212610006332, z: 0.0252532958984375, score: undefined, name: 'middle_finger_tip'},
    {x: 0.004547036252915859, y: 0.0008531166240572929, z: 0.0029315948486328125, score: undefined, name: 'ring_finger_mcp'},
    {x: 0.010403755120933056, y: -0.021624816581606865, z: 0.005985260009765625, score: undefined, name: 'ring_finger_pip'},
    {x: 0.016020918264985085, y: -0.04513907805085182, z: 0.013397216796875, score: undefined, name: 'ring_finger_dip'},
    {x: 0.019796956330537796, y: -0.0639178603887558, z: 0.028717041015625, score: undefined, name: 'ring_finger_tip'},
    {x: 0.013026394881308079, y: 0.006046573631465435, z: 0.01361083984375, score: undefined, name: 'pinky_finger_mcp'},
    {x: 0.01977556198835373, y: -0.01196362916380167, z: 0.01399993896484375, score: undefined, name: 'pinky_finger_pip'},
    {x: 0.03071749210357666, y: -0.02825966849923134, z: 0.013580322265625, score: undefined, name: 'pinky_finger_dip'},
    {x: 0.037425778806209564, y: -0.041194647550582886, z: 0.0188751220703125, score: undefined, name: 'pinky_finger_tip'},
  ],
};

import {Vector3} from 'three';

import CONFIG from '../config.js';
import {posFromThreejsToCentered} from '../helpers.js';

import VectorsBank from '../utils/VectorsBank.js';
import RotationConstraintsIK from './RotationConstraintsIK.js';
import PalmCentersManager from '../managers/PalmCentersManager.js';
import HandRotLookAtStrategy from './handrot/HandRotLookAtStrategy.js';
// import HandRot2dProblemStrategy from './handrot/HandRot2dProblemStrategy.js';

const _vec3 = new Vector3();

const ENFORCE_FINGER_CONSTRAINTS = true;

/**
 * Class responsible for applying hand tracking datas to a hand rig
 */
class RiggingStrategy {
  constructor(handRig) {
    this._handRig = handRig;
    this._bones = handRig._bones;
    this._joints = null;
    this._vectorsBank = new VectorsBank();

    this._estimatedHandCenter = new Vector3(0, 0, 0);
    this._estimatedHandRotationZ = 0;

    this._handRotationStrategy = new HandRotLookAtStrategy(this);
    // this._handRotationStrategy = new HandRot2dProblemStrategy(this);
  }

  initializeAfterReady() {
    /*
    if (typeof this._handRotationStrategy.createView === 'function') {
      this._handRig._scene.mainContainer.add( this._handRotationStrategy.createView() );
    }
    */
  }

  /**
   * Receive hand tracking datas and handle it
   */
  handleTrackingInput(handData) {
    const armature = this._handRig._armature;

    armature.scale.x = 1;
    armature.scale.y = 1;
    armature.scale.z = 1;

    // convert received data into something we can then use
    const handPoints = handData.keypoints3D;
    this._joints = handPoints.reduce((object, item) => {
      object[item.name] = this._convert3DHandPositionToThreejs(item);
      return object;
    }, {});

    // NOTE: parents should be scaled uniformly BEFORE entering those calculations otherwise the result will be wrong !
    // that's why I reset the scaling of armature and put it back afterward !
    if (CONFIG.BONES_ROTATED) {
      this._updateEstimatedHandCenter();
      this._updateEstimatedHandRotation();
      this._placeBonesWithRotation();
    } else {
      this._placeBonesWithPosition();
    }

    armature.scale.x = this._handRig._isInverted ? -armature.scale.x : armature.scale.x;
    armature.scale.z = (CONFIG.FRONT_FACING_CAMERA && CONFIG.FIRST_PERSON_MODE) || (!CONFIG.FRONT_FACING_CAMERA && !CONFIG.FIRST_PERSON_MODE) ? -armature.scale.z : armature.scale.z;

    armature.scale.x *= CONFIG.HAND_LOCAL_SCALE;
    armature.scale.y *= CONFIG.HAND_LOCAL_SCALE;
    // armature.scale.z *= CONFIG.HAND_LOCAL_SCALE; // @TODO this somehow break flat shadow

    // update palm center
    this._bones.middle_finger_mcp.getWorldPosition(_vec3);
    posFromThreejsToCentered(_vec3);
    PalmCentersManager.setCurrentPalmCenter(this._handRig, _vec3.x, _vec3.y, _vec3.z);

    // update index finger position
    this._bones.index_finger_tip.getWorldPosition(_vec3);
    posFromThreejsToCentered(_vec3);
    PalmCentersManager.setCurrentIndexPosition(this._handRig, _vec3.x, _vec3.y, _vec3.z);
  }

  _placeBonesWithPosition() {
    Object.keys(this._joints).forEach((name) => {
      this._placeBoneAtDesiredPosition(name);
    });
  }

  _placeBonesWithRotation() {
    this._placeBoneAtDesiredPosition('wrist');
    this._boneLookAtPosition(this._bones.wrist, this._estimatedHandCenter, true);

    this._boneLookAtPosition(this._bones.index_finger_mcp, this._joints['index_finger_pip']);
    this._boneLookAtPosition(this._bones.index_finger_pip, this._joints['index_finger_dip']);
    this._boneLookAtPosition(this._bones.index_finger_dip, this._joints['index_finger_tip']);

    this._boneLookAtPosition(this._bones.middle_finger_mcp, this._joints['middle_finger_pip']);
    this._boneLookAtPosition(this._bones.middle_finger_pip, this._joints['middle_finger_dip']);
    this._boneLookAtPosition(this._bones.middle_finger_dip, this._joints['middle_finger_tip']);

    this._boneLookAtPosition(this._bones.ring_finger_mcp, this._joints['ring_finger_pip']);
    this._boneLookAtPosition(this._bones.ring_finger_pip, this._joints['ring_finger_dip']);
    this._boneLookAtPosition(this._bones.ring_finger_dip, this._joints['ring_finger_tip']);

    this._boneLookAtPosition(this._bones.pinky_finger_mcp, this._joints['pinky_finger_pip']);
    this._boneLookAtPosition(this._bones.pinky_finger_pip, this._joints['pinky_finger_dip']);
    this._boneLookAtPosition(this._bones.pinky_finger_dip, this._joints['pinky_finger_tip']);

    this._boneLookAtPosition(this._bones.thumb_cmc, this._joints['thumb_mcp']);
    this._boneLookAtPosition(this._bones.thumb_mcp, this._joints['thumb_ip']);
    this._boneLookAtPosition(this._bones.thumb_ip, this._joints['thumb_tip']);

    if (ENFORCE_FINGER_CONSTRAINTS) {
      // hardcoded fixes (@TODO might not be relevent forever...)
      if (!CONFIG.EXPERIMENTAL_TRACKING) {
        // note: bend index finger outward a bit because it always seem to be bent inward !
        this._bones.index_finger_mcp.rotation.z -= 0.05;

        // note: index finger is not straight by default, it is always bent inward a little
        this._bones.index_finger_pip.rotation.x -= 0.2;
        this._bones.index_finger_dip.rotation.x -= 0.265;
        this._bones.index_finger_tip.rotation.x -= 0.4;
      }

      RotationConstraintsIK.enforceMetacarpalsConstraints([
        this._bones.index_finger_mcp,
        this._bones.middle_finger_mcp,
        this._bones.ring_finger_mcp,
        this._bones.pinky_finger_mcp,
      ]);

      RotationConstraintsIK.enforceFingerConstraints([
        this._bones.index_finger_tip,
        this._bones.index_finger_dip,
        this._bones.index_finger_pip,
        this._bones.middle_finger_tip,
        this._bones.middle_finger_dip,
        this._bones.middle_finger_pip,
        this._bones.ring_finger_tip,
        this._bones.ring_finger_dip,
        this._bones.ring_finger_pip,
        this._bones.pinky_finger_tip,
        this._bones.pinky_finger_dip,
        this._bones.pinky_finger_pip,
      ]);

      RotationConstraintsIK.enforceFingerConstraints([
        this._bones.thumb_tip,
        this._bones.thumb_ip,
        this._bones.thumb_mcp,
      ], true);
    }

    // set tip rotation to be a pale copy of the previous bone rotation
    this._bones.index_finger_tip.rotation.set(this._bones.index_finger_dip.rotation.x * 0.5, 0, 0);
    this._bones.middle_finger_tip.rotation.set(this._bones.middle_finger_dip.rotation.x * 0.5, 0, 0);
    this._bones.ring_finger_tip.rotation.set(this._bones.ring_finger_dip.rotation.x * 0.5, 0, 0);
    this._bones.pinky_finger_tip.rotation.set(this._bones.pinky_finger_dip.rotation.x * 0.5, 0, 0);
    this._bones.thumb_tip.rotation.set(this._bones.thumb_ip.rotation.x * 0.5, 0, 0);

    // note: if I enforce constraints, final tip position might not end up where it should
    if (!ENFORCE_FINGER_CONSTRAINTS) {
      this._placeBoneAtDesiredPosition('index_finger_tip');
      this._placeBoneAtDesiredPosition('middle_finger_tip');
      this._placeBoneAtDesiredPosition('ring_finger_tip');
      this._placeBoneAtDesiredPosition('pinky_finger_tip');
      this._placeBoneAtDesiredPosition('thumb_tip');
    }
  }

  _updateEstimatedHandCenter() {
    this._estimatedHandCenter.set(0, 0, 0);

    for (let i = 0; i < this.bonesUsedForPalmCalculations.length; i++) {
      this._estimatedHandCenter.add( this._joints[this.bonesUsedForPalmCalculations[i]] );
    }

    this._estimatedHandCenter.divideScalar( this.bonesUsedForPalmCalculations.length );
  }

  _updateEstimatedHandRotation() {
    this._estimatedHandRotationZ = this._handRotationStrategy.estimateHandRotationZ();
  }

  _convert3DHandPositionToThreejs(point) {
    return this._vectorsBank.getVector3(point.name, point.x, point.y, point.z);
  }

  _boneLookAtPosition(bone, position, applyRotation = false) {
    bone.lookAt(position);

    bone.rotation.z = applyRotation ? this._estimatedHandRotationZ : 0;
    bone.rotateX(Math.PI * 0.5);

    this._placeBoneChildsAtDesiredPosition(bone);
  }

  _placeBoneAtDesiredPosition(boneName) {
    const bone = this._bones[boneName];
    const parent = bone.parent;

    this._handRig._scene.attach(bone);

    bone.position.copy(this._joints[boneName]);

    parent.attach(bone);

    // ensure float precision issues doesn't go out of... hands.
    bone.scale.set(1, 1, 1);
  }

  _placeBoneChildsAtDesiredPosition(bone) {
    // note: clone bones array first because their order might change during the manipulations we do inside this._placeBoneAtDesiredPosition
    const arr = [].concat(bone.children).filter((child) => { return child.isBone; });

    for (let i = 0; i < arr.length; i++) {
      this._placeBoneAtDesiredPosition(arr[i].name);
    }
  }
}

RiggingStrategy.prototype.bonesUsedForPalmCalculations = [
  'wrist',
  // 'thumb_mcp',
  'index_finger_mcp',
  'pinky_finger_mcp',
];

export default RiggingStrategy;

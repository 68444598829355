import {IS_ANDROID} from '../env.js';
import CONFIG from '../config.js';
import CONTEXT from '../context.js';
import VideoPlayer from './VideoPlayer.js';

// @TODO commit a good video sample and use it here
// import lazyDebugVideoPath from '../assets/videos/video-interaction-sample.mp4';

const VIDEO_FLOAT_ABOVE = true;

/**
 * Singleton to encapsulate a video stream from user's media devices
 */
class UserCamera {
  constructor() {
    this.videoPlayer = new VideoPlayer({
      coverWholeBackground: false,
      flipHorizontal: CONFIG.FRONT_FACING_CAMERA,
    });
    this.videoPlayer._containerClasses.push(VIDEO_FLOAT_ABOVE ? 'floating-above' : 'fullscreen-under');

    if (CONFIG.ALLOW_CAMERA_FLIP && VIDEO_FLOAT_ABOVE) {
      this.videoPlayer._containerElem.addEventListener('click', this._onClickedVideoPlayer.bind(this));
      this.videoPlayer._containerElem.style.cursor = 'pointer';

      this._flipIcon = document.createElement('span');
      this._flipIcon.className = 'material-icons';
      this._flipIcon.innerText = this._getFlipIconIdent(true);
      this._flipIcon.style.position = 'absolute';
      this._flipIcon.style.bottom = this._flipIcon.style.right = '3px';
      this._flipIcon.style.fontSize = '20px';
      this._flipIcon.style.color = '#ffffff';
      this._flipIcon.style.opacity = '0.8';
      this._flipIcon.style.textShadow = '0px 0px 4px #000000';
      this.videoPlayer._containerElem.appendChild(this._flipIcon);
    }

    this.videoFrameRate = 0;

    this._reset();
  }

  isReady() {
    return this._isReady;
  }

  _reset() {
    if (this._videoTrack) {
      this._videoTrack.stop();
    }

    this._stream = null;
    this._videoTrack = null;
    this._isReady = false;
  }

  initializeUserCamera() {
    if (CONFIG.LAZY_DEBUG_MODE) {
      return this._initializeFromPreRecordedVideo(lazyDebugVideoPath);
    }

    const mediasConstraints = {
      audio: false,
      video: this._getVideoConstraints(),
    };

    return new Promise((resolve, reject) => {
      // NOTE: must be in a secure context to have access to those (HTTPS or localhost)
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('Browser API navigator.mediaDevices.getUserMedia not available');
      }

      // check support
      const supports = navigator.mediaDevices.getSupportedConstraints();
      if (!supports.facingMode) {
        console.warn('FacingModes not supported !');
      }

      navigator.mediaDevices.getUserMedia(mediasConstraints).then(async (stream) => {
        this._stream = stream;

        await this.videoPlayer.setSrcObject(stream);

        this.videoPlayer.play();

        const tracks = stream.getVideoTracks();
        if (tracks.length) {
          this._videoTrack = tracks[0];
          this._updateSettings();
        }

        this._isReady = true;

        resolve();
      })
      .catch(reject);
    });
  }

  _initializeFromPreRecordedVideo(url) {
    return new Promise(async (resolve, reject) => {
      await this.videoPlayer.setSrcUrl(url);
      this.videoPlayer.updateSettings({
        autoLoop: true,
      });

      this.videoPlayer.play();

      this.videoFrameRate = 30;

      this._isReady = true;

      resolve();
    });
  }

  /**
   * desiredRatio = ( viewport | squared | landscape | portrait )
   */
  _getVideoConstraints(desiredRatio, maxSize = 640) {
    if (typeof desiredRatio === 'undefined') {
      desiredRatio = CONFIG.EXPERIMENTAL_TRACKING ? 'viewport' : 'squared';
    }

    const videoConstraints = {
      facingMode: CONFIG.FRONT_FACING_CAMERA ? 'user' : 'environment',
      // frameRate: {
      //   max: 30,
      // },
    };

    switch (desiredRatio) {
      case 'viewport':
        let width = maxSize;
        let height = maxSize;
        if (CONTEXT.APP.viewportRatio > 1) {
          height = Math.round(height * (1 / CONTEXT.APP.viewportRatio));
        }
        if (CONTEXT.APP.viewportRatio < 1) {
          width = Math.round(width * CONTEXT.APP.viewportRatio);
        }

        // videoConstraints.aspectRatio = CONTEXT.APP.viewportRatio; // @TODO dont work well on my phone !
        videoConstraints.width = {
          max: maxSize,
          ideal: width,
        };
        videoConstraints.height = {
          max: maxSize,
          ideal: height,
        };
      break;

      case 'squared' :
        videoConstraints.aspectRatio = 1;
        videoConstraints.width = {
          max: maxSize,
        };
        videoConstraints.height = {
          max: maxSize,
        };
      break;

      case 'landscape' :
        videoConstraints.aspectRatio = 16 / 9;
      break;

      case 'portrait' :
        videoConstraints.aspectRatio = 9 / 16;
      break;
    }

    return videoConstraints;
  }

  _getFlipIconIdent(initialIcon = false) {
    if (initialIcon) {
      return IS_ANDROID ? 'flip_camera_android' : 'flip_camera_ios';
    } else {
      return CONFIG.FRONT_FACING_CAMERA ? 'camera_front' : 'camera_rear';
    }
  }

  _updateSettings() {
    const settings = this._videoTrack.getSettings();
    this.videoFrameRate = settings.frameRate;
  }

  // flips the camera, then update the video feed
  async _onClickedVideoPlayer() {
    if (!this._isReady || CONTEXT.SHOWING_HAND_TUTO) {
      return;
    }

    CONFIG.FRONT_FACING_CAMERA = !CONFIG.FRONT_FACING_CAMERA;

    if (this._flipIcon) {
      this._flipIcon.innerText = 'hourglass_bottom';
    }
    this.videoPlayer._containerElem.style.filter = 'grayscale(1) brightness(0.75)';

    // note: unable to switch camera on the fly in chrome android,
    // see: https://bugs.chromium.org/p/chromium/issues/detail?id=581401
    // note: well, and it does not seems to work on iPhone either
    const dynamicSwitchSupported = false;

    // update constraints of existing video stream
    if (this._videoTrack && dynamicSwitchSupported) {
      this._isReady = false;
      await this._videoTrack.applyConstraints(this._getVideoConstraints());
      this._updateSettings();
      this._isReady = true;
    }

    // otherwise, request a new video stream
    else {
      this._reset();
      await this.initializeUserCamera();
    }

    this.videoPlayer.setHorizontalFlip(CONFIG.FRONT_FACING_CAMERA);

    if (this._flipIcon) {
      this._flipIcon.innerText = this._getFlipIconIdent();
    }
    this.videoPlayer._containerElem.style.filter = '';
  }
}

export default new UserCamera();

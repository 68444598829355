/**
 * Singleton class to keep track of poses curently made
 */
class PosesManager {
  constructor() {
    this._currentPosesMap = new Map();
  }

  setCurrentPoses(handRig, poses) {
    this._currentPosesMap.set(handRig, poses);
  }

  isPoseCurrentlyMade(poseIdent) {
    for (const [key, value] of this._currentPosesMap) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].ident === poseIdent) {
          return true;
        }
      }
    }

    return false;
  }
}

export default new PosesManager();

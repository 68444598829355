import _defaults from 'lodash/defaults.js';

/**
 * Add playlist functionality to VideoPlayer
 */
export default {
  setPlaylist(arr = [], autoNext = true) {
    this._currentPlaylistArr = arr;
    this._currentPlaylistIndex = 0;
    this._currentPlaylistAutoNext = autoNext;

    if (autoNext) {
      this._currentPlaylistLoopListener = this.on('video-ended', this.playNextPlaylistItem.bind(this));
    } else {
      this.once('video-started', () => {
        this._enableClickToNextPlaylistItem();
      });
    }

    this.playCurrentPlaylistItem();
  },

  playCurrentPlaylistItem(andPlay = false) {
    const obj = this._currentPlaylistArr[this._currentPlaylistIndex];
    const promise = this.setSrcUrl(obj.videoUrl);
    const videoSettings = _defaults({autoLoop: !this._currentPlaylistAutoNext}, obj.videoSettings || {});

    this.updateSettings(videoSettings);
    this.enableHandSizeControl(obj.handSizeScale || 1);

    if (andPlay) {
      promise.then(this.play.bind(this));
    }
  },

  playNextPlaylistItem() {
    this._currentPlaylistIndex = this._currentPlaylistIndex < this._currentPlaylistArr.length - 1 ? this._currentPlaylistIndex + 1 : 0;
    this.playCurrentPlaylistItem(true);
  },

  playPreviousPlaylistItem() {
    this._currentPlaylistIndex = this._currentPlaylistIndex > 0 ? this._currentPlaylistIndex - 1 : this._currentPlaylistArr.length - 1;
    this.playCurrentPlaylistItem(true);
  },

  _enableClickToNextPlaylistItem() {
    const elem = document.createElement('div');
    elem.classList.add('playlist-controls');

    const btnPrev = document.createElement('button');
    btnPrev.classList.add('left');
    btnPrev.innerHTML = 'Previous';
    btnPrev.addEventListener('click', this.playPreviousPlaylistItem.bind(this));

    const btnNext = document.createElement('button');
    btnNext.classList.add('right');
    btnNext.innerHTML = 'Next';
    btnNext.addEventListener('click', this.playNextPlaylistItem.bind(this));

    elem.appendChild(btnPrev);
    elem.appendChild(btnNext);
    this._containerElem.appendChild(elem);
  },
}

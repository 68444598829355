import CONFIG from '../config.js';

import AbstractTrackingDataTweaker from './AbstractTrackingDataTweaker.js';

/**
 * Takes incoming hand tracking data and fix it using joints constraints and other things
 */
class TrackingDataIK extends AbstractTrackingDataTweaker {

  constructor(riggingProfile) {
    super();
    this._riggingProfile = riggingProfile;
  }

  /**
   * Try to fix inaccuracies in the tracking data
   */
  handleTrackingInput(handData) {
    const inverted = handData.handedness === 'Left';
    const handPoints = handData.keypoints3D;

    const pointsByName = {};
    for(let i = 0; i < handPoints.length; i++) {
      pointsByName[ handPoints[i].name ] = handPoints[i];
    }

    this._enforceBoneLengths(this._riggingProfile, pointsByName);
    this._enforceKnuckleSpacings(this._riggingProfile, pointsByName);

    // @TODO re-ensure lenghts is ok after the spread pass, not sure about that yet
    this._enforceBoneLengths(this._riggingProfile, pointsByName);
  }

  /**
   * Make sure every bone keep the same length they had originaly in the 3D model
   */
  _enforceBoneLengths(profile, pointsByName) {
    let boneName, desiredDistance, bonesAffected, pointChild, pointParent, currentDistance, decalDistance, decalDirection;
    for (let i = 0; i < profile.bonesList.length; i++) {
      boneName = profile.bonesList[i];
      desiredDistance = profile.boneLengths[boneName];
      bonesAffected = profile.boneChilds[boneName];
      pointChild = pointsByName[boneName];
      pointParent = pointsByName[profile.boneParents[boneName]];

      if (pointParent) {
        currentDistance = this._getDistanceBetweenPoints(pointChild, pointParent);
        decalDistance = desiredDistance - currentDistance;
        decalDirection = this._getDirectionBetweenPoints(pointChild, pointParent);

        for (let j = 0; j < bonesAffected.length; j++) {
          this._movePointInDirection(pointsByName[bonesAffected[j]], decalDirection, decalDistance);
        }
      }
    }
  }

  /**
   * The idea is to force a distance between some knuckles that should not move closer or apart from each others
   */
  _enforceKnuckleSpacings(profile, pointsByName) {
    // @TODO smooth knuckles position first ? (around a line)

    // spread fingers v1
    // this._spreadKnuckles(profile, pointsByName, 'index_finger_mcp', 'pinky_finger_mcp');
    // this._spreadKnuckles(profile, pointsByName, 'middle_finger_mcp', 'ring_finger_mcp');

    // spread fingers v2
    this._spreadKnuckles(profile, pointsByName, 'index_finger_mcp', 'pinky_finger_mcp');
    this._spreadKnuckles(profile, pointsByName, 'index_finger_mcp', 'middle_finger_mcp', 1);
    this._spreadKnuckles(profile, pointsByName, 'pinky_finger_mcp', 'ring_finger_mcp', 1);

    // spread thumb
    this._spreadKnuckles(profile, pointsByName, 'thumb_cmc', 'pinky_finger_mcp', 0, 0.5);
    this._spreadKnuckles(profile, pointsByName, 'wrist', 'ring_finger_mcp', 0, 0.5);
  }

  // distribution : (0.5 = spread evenly, 0 = all toward boneA, 1 = all toward boneB)
  _spreadKnuckles(profile, pointsByName, boneNameA, boneNameB, distribution = 0.5, influence = 1) {
    const pointA = pointsByName[boneNameA];
    const pointB = pointsByName[boneNameB];
    const currentDistance = this._getDistanceBetweenPoints(pointA, pointB);
    const desiredDistance = profile.knucklesDistances[boneNameA][boneNameB];
    const decalDistanceA = (desiredDistance - currentDistance) * (1 - distribution) * influence;
    const decalDistanceB = (desiredDistance - currentDistance) * distribution * influence;
    const decalDirection = this._getDirectionBetweenPoints(pointA, pointB);

    let mult = 1;

    const bonesAffectedA = profile.boneChilds[boneNameA];
    for (let i = 0; i < bonesAffectedA.length; i++) {
      mult = 1 - ((1 / bonesAffectedA.length) * i);
      this._movePointInDirection(pointsByName[bonesAffectedA[i]], decalDirection, decalDistanceA * mult);
    }

    decalDirection.negate();

    const bonesAffectedB = profile.boneChilds[boneNameB];
    for (let i = 0; i < bonesAffectedB.length; i++) {
      mult = 1 - ((1 / bonesAffectedB.length) * i);
      this._movePointInDirection(pointsByName[bonesAffectedB[i]], decalDirection, decalDistanceB * mult);
    }
  }
}

export default TrackingDataIK;

import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'

const MIXINS = [
  HasEventsMixin,
];

/**
 * Singleton class to handle the window size and viewport size stuff
 *
 * May emit those events :
 * - visibility-changed   = after the viewport visibility changed
 * - viewport-resized     = after the viewport has been resized
 * - viewport-scrolled    = after the user has scrolled
 */
class ViewportManager {
  constructor() {
    MixinsComposer.construct(this, MIXINS);

    this.viewportVisible = true;
    this._setFromWindowSizes();
    this._setScrollState();

    this._enableVisibilityListener();
    this._enableResizeListener();
    this._enableScrollListener();
  }

  _enableVisibilityListener() {
    // watch visibility (using visibility API)
    if (typeof window.document.hidden !== 'undefined') {
      const updateFunc = () => { this._changeVisibility(!window.document.hidden); };
      window.addEventListener('visibilitychange', updateFunc);
      updateFunc();
    }

    // watch visibility (using classic focus/blur events)
    else {
      window.addEventListener('focus', this._changeVisibility.bind(this, true));
      window.addEventListener('blur', this._changeVisibility.bind(this, false));
      this._changeVisibility( window.document.hasFocus() );
    }
  }

  _enableResizeListener() {
    window.addEventListener('resize', this._onViewportResized.bind(this));
  }

  _enableScrollListener() {
    window.addEventListener('scroll', this._onScroll.bind(this));
  }

  _changeVisibility(visible = true) {
    this.viewportVisible = visible;
    this.emit('visibility-changed', this.viewportVisible);
  }

  _setFromWindowSizes() {
    this.viewportWidth = window.innerWidth;
    this.viewportHeight = window.innerHeight;
    this.viewportRatio = this.viewportWidth / this.viewportHeight;
  }

  _setScrollState() {
    this.viewportScrollX = window.scrollX;
    this.viewportScrollY = window.scrollY;
  }

  _onViewportResized() {
    this._setFromWindowSizes();
    this.emit('viewport-resized', this.viewportWidth, this.viewportHeight);
  }

  _onScroll(evt) {
    this._setScrollState();
    this.emit('viewport-scrolled', this.viewportScrollX, this.viewportScrollY);
  }
}

MixinsComposer.extend(ViewportManager).with(...MIXINS);

export default new ViewportManager();

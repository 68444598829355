import KalmanFilter from 'kalmanjs';

class KalmanFilter2D {
  constructor(settings) {
    this.smoothX = new KalmanFilter(settings);
    this.smoothY = new KalmanFilter(settings);
  }

  smoothKeypoint(keypoint) {
    this.smoothX.filter(keypoint.x);
    this.smoothY.filter(keypoint.y);

    keypoint.x = this.smoothX.x;
    keypoint.y = this.smoothY.x;
  }

  // update setting Q
  setMeasurementNoise(noise) {
    this.smoothX.setMeasurementNoise(noise);
    this.smoothY.setMeasurementNoise(noise);
  }
}

export default KalmanFilter2D;

import {IS_PROD,IS_MOBILE} from '../../env.js';
import CONFIG from '../../config.js';
import CONTEXT from '../../context.js';
import AbstractScene from './AbstractScene.js';
import VideoPlayer from '../../medias/VideoPlayer.js';

// tweak some configs, only in this scene !
CONFIG.MATURE_CONTENT = true;
CONFIG.FINGER_PENETRATION_MULT = 1.3;
CONFIG.POSE_DETECTION = false;

import videoPath1 from '/src/assets/videos/video-mature-content-1.mp4';
import videoPath2 from '/src/assets/videos/video-mature-content-2.mp4';
import videoPath3 from '/src/assets/videos/video-mature-content-3.mp4';
import videoPath4 from '/src/assets/videos/video-mature-content-4.mp4';
import videoPath5 from '/src/assets/videos/video-mature-content-5.mp4';
import videoPath6 from '/src/assets/videos/video-mature-content-6.mp4';
import videoPath7 from '/src/assets/videos/video-mature-content-7.mp4';

const MOBILE_PLAYLIST = [
  {
    videoUrl: videoPath6,
    handSizeScale: IS_MOBILE ? 1.15 : 2.25,
    videoSettings: {
      coverWholeBackground: IS_MOBILE,
      playbackRate: 0.85,
    },
  },
  {
    videoUrl: videoPath5,
    handSizeScale: IS_MOBILE ? 1.25 : 2.5,
    videoSettings: {
      coverWholeBackground: IS_MOBILE,
      playbackRate: 0.75,
    },
  },
  {
    videoUrl: videoPath3,
    handSizeScale: IS_MOBILE ? 0.8 : 1.9,
    videoSettings: {
      coverWholeBackground: IS_MOBILE,
      playbackRate: 0.75,
    },
  },
  {
    videoUrl: videoPath2,
    handSizeScale: IS_MOBILE ? 0.8 : 1.9,
    videoSettings: {
      coverWholeBackground: IS_MOBILE,
      playbackRate: 0.75,
    },
  },
  {
    videoUrl: videoPath4,
    handSizeScale: IS_MOBILE ? 0.9 : 2,
    videoSettings: {
      coverWholeBackground: IS_MOBILE,
      playbackRate: 1.25,
    },
  },
];

const DESKTOP_PLAYLIST = [
  {
    videoUrl: videoPath1,
    handSizeScale: 1.35,
    videoSettings: {
      coverWholeBackground: false,
      playbackRate: 1.25,
    },
  },
  {
    videoUrl: videoPath7,
    handSizeScale: 1.25,
    videoSettings: {
      coverWholeBackground: false,
      playbackRate: 0.75,
    },
  },
];

// const PLAYLIST = IS_MOBILE ? MOBILE_PLAYLIST : DESKTOP_PLAYLIST;

// @TODO mobile videos are more numerous and suitable, lets use them on desktop for now, but would be better with good horizontal videos !
const PLAYLIST = MOBILE_PLAYLIST;

/**
 * A scene used to render meta hands above the whole website
 */
class AdultsIndustryScene extends AbstractScene {
  constructor() {
    super(...arguments);

    CONFIG.HAND_DEPTH_CENTER = 1;

    this.bgVideoPlayer = new VideoPlayer({
      coverWholeBackground: false,
      autoLoop: false,
    });
    this._hideMatureContent();
    this.bgVideoPlayer._containerClasses.push('fullscreen-under');
    this.bgVideoPlayer.injectInDOM(CONTEXT.APP._domContainer); // @TODO accessing private prop, should do better !
    this.bgVideoPlayer.setPlaylist(PLAYLIST, false);
    this.bgVideoPlayer.enableClickToPlayStop();

    this.createShadowPlane();

    /*
    if (!IS_PROD) {
      window.addEventListener('keydown', (evt) => {
        if (evt.key === ' ') {
          this.bgVideoPlayer.playNextPlaylistItem();
          console.log('PLAYLIST NEXT');

          // this.bgVideoPlayer.playPreviousPlaylistItem();
          // console.log('PLAYLIST PREVIOUS');
        }
      });
    }
    */
  }

  _hideMatureContent() {
    this.bgVideoPlayer._containerElem.style.filter = 'blur(15px)';
  }

  _showMatureContent() {
    this.bgVideoPlayer._containerElem.style.filter = '';
  }

  async onUserReady() {
    await CONTEXT.APP.initializeCameraVideo();
    CONTEXT.APP.startCameraTracking();
    this._showMatureContent();
    this.bgVideoPlayer.play();
  }
}

export default AdultsIndustryScene;

import _forEach from 'lodash/forEach.js';
import _compact from 'lodash/compact.js';

/**
 * Inject methods in the prototype of a class (and/or do stuff during instanciation)
 *
 * Usage example :
 *
 * const MIXINS = [SampleMixin];
 * class SampleClass {
 *   constructor() {
 *     MixinsComposer.construct(this, MIXINS);
 *   }
 * }
 * MixinsComposer.extend(SampleClass).with(...MIXINS);
 *
 */
export default {
  construct(context, includeArr = []) {
    if(typeof context._mixins_toConstruct !== 'undefined') {

      if(typeof context._mixins_constructed === 'undefined') {
        context._mixins_constructed = [];
      }

      const includeMethods = _compact(includeArr.map((mixin) => { return mixin.constructor; }));

      for(let i = 0; i < context._mixins_toConstruct.length; i++) {
        const method = context._mixins_toConstruct[i];
        const canDoExecuteItNow = !includeMethods.length || includeMethods.indexOf(method) !== -1;

        if(canDoExecuteItNow && context._mixins_constructed.indexOf(method) === -1) {
          method.call(context);
          context._mixins_constructed.push(method);
        }
      }
    }
  },

  extend(context) {
    return {
      with(...mixins) {
        extendClassWithMixins(context, mixins);
      },
    };
  },
};


function extendClassWithMixins(context, mixins) {
  const executeInConstruct = [];

  for(let i = 0; i < mixins.length; i++) {
    _forEach(mixins[i], (value, key) => {
      switch(key) {
        // accumulate functions to be executed during instanciation
        case 'constructor' :
          executeInConstruct.push(value);
        break;

        // apply everything else on the prototype
        default :
          if(typeof context.prototype[key] === 'undefined') {
            Object.defineProperty(context.prototype, key, {
              value: value,
              writable: typeof value !== 'function',
            });
          } else {
            console.warn((typeof value) + ' "'+ key +'" already defined on prototype');
          }
        break;
      }
    });
  }

  // save functions to be executed during instanciation
  if(executeInConstruct.length) {
    if(typeof context.prototype._mixins_toConstruct === 'undefined') {
      context.prototype._mixins_toConstruct = executeInConstruct;
    } else {
      context.prototype._mixins_toConstruct.push(...executeInConstruct);
    }
  }
};

import {IS_MOBILE, IS_PROD} from './env.js';

const TEST_MODE = false;
const CLEAN_MODE = true;
const EXPERIMENTAL_MODE = false;

/**
 * Contain settings affecting the whole app
 */
export default {
  // general
  GOD_MODE                : true, // if we want the hand to be visible on top of the whole website instead of confined
  FIRST_PERSON_MODE       : true, // if we want to show hands in first person
  FRONT_FACING_CAMERA     : true, // if we want to use the front facing camera or rear facing camera
  ALLOW_CAMERA_FLIP       : true, // if we want to allow the user to switch camera (front / rear) on the fly
  REQUEST_USER_ACTION     : true, // if we want to request a user action before accessing the camera
  MATURE_CONTENT          : false, // if this website contains mature content
  LEGACY_CENTERING        : false, // if I want to still use how things where all centered on screen before

  // hand tracking
  MAX_TRACKING_FPS        : IS_MOBILE ? 24 : 30, // maximum FPS of hands tracking
  SANITIZE_TRACKING_DATA  : true, // if we want to sanitize tracking data before it being used
  IK_TRACKING_DATA        : !EXPERIMENTAL_MODE, // if we want to apply homemade inverse kinematics to the tracking data (to improve it's flaws)
  DEPTH_ESTIMATION        : true, // if we want to estimate depth of the hand and move it along Z axis
  SMOOTH_TRACKING_DATA    : true, // if we want to smooth tracking data
  SMOOTH_POSE_TRACKING    : false, // if we want to smooth hand pose tracking
  POSE_DETECTION          : true, // if we want to enable hand pose tracking
  EXPERIMENTAL_TRACKING   : EXPERIMENTAL_MODE, // if we want to use my new experimental tracking technique !

  // hand rigging
  WRIST_CENTERED          : true, // center hand on wrist or not
  HAND_TRANSLATED         : true, // translate the hand in 2D space (left/right, up/down) or not
  BONES_ROTATED           : true, // rotate bones properly (who wouldn't want that ?)
  HAND_MOVEMENT_RANGE     : 0.45, // how much the hand can move in 3D space
  HAND_DEPTH_CENTER       : 0, // [-1 to 1] -1 = closest, 1 = fartest
  HAND_LOCAL_SCALE        : 1, // local scaling to apply to each hands models (to fit the content scaling underneath)
  HAND_LOCAL_SCALE_FACTOR : 1, // multiplier used to adjust the hand size relative to the content size scaling
  BOTH_HANDS_ENABLED      : !IS_MOBILE, // if we want to enable both hands tracking
  // PUT_YOUR_HANDS_UP       : true, // if we want to slightly decal hands up (a request from russell) so it feels more natural (mostly on mobile)

  // threejs rendering
  RENDERER_RESOLUTION     : 1, // [1 - 0], 1 = full resolution, 0.5 = half, 0 = ...why would you do that?
  RECT_AREA_LIGHT_ON      : false, // if we want to enable rect area light in threejs scenes
  SHADOW_CASTING_ON       : true, // if we want to enable shadow casting in threejs scenes
  FINGER_PENETRATION_MULT : 1.45, // used to tweak finger penetration

  // quick debug mode (with hands tracking data samples)
  QUICK_DEBUG_MODE        : false, // skip camera and tracking steps, use pre-defined sample data instead
  QUICK_DEBUG_INTERVAL    : 750, // how fast each poses rotates (set to 0 to disable)

  // lazy debug mode (with pre-recorded video sample)
  LAZY_DEBUG_MODE         : false,

  // video sequence debug mode (accelerate debugging of video sequences interactions scenes)
  VID_SEQUENCE_DEBUG_MODE : false,

  // debug helpers
  DEBUG_FPS_STATS         : !CLEAN_MODE, // enable fps stats panel or not
  DEBUG_GUI_OPTIONS_PANEL : !CLEAN_MODE, // enable the GUI options panel
  DEBUG_GENERIC_HELPERS   : TEST_MODE, // display generic helpers or not
  DEBUG_SKELETON_HELPERS  : TEST_MODE, // display skeleton helper or not
  DEBUG_POINTS_HELPERS    : TEST_MODE, // display points helper or not
  DEBUG_ORBIT_CONTROLS    : !CLEAN_MODE || TEST_MODE, // enable orbit controls or not
  DEBUG_INTERACTIVE_CIRCLE: false, // temporary debug for interactive circles inside a VideoTimeline, because it's hard to test quickly otherwise... !
};

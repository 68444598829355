import {
  Points,
  PointsMaterial,
  BufferGeometry,
  Float32BufferAttribute,
} from 'three';

const POINTS_MATERIAL = new PointsMaterial({
  size: 0.0075,
  color: 0xff0000,
  depthTest: false,
});

/**
 * Class to visualize hand tracking positions as points in 3D space
 */
class PointsHelper extends Points {
  constructor() {
    super(new BufferGeometry(), POINTS_MATERIAL.clone());

    // note: if the position attribute is not defined in the first render, points will never be rendered apparently
    this.geometry.setAttribute( 'position', new Float32BufferAttribute( [], 3 ) );
  }

  updateFromKeypoints3D(keypoints3D) {
    const pointsVertices = [];

    for (let i = 0; i < keypoints3D.length; i++) {
      pointsVertices.push(keypoints3D[i].x, keypoints3D[i].y, keypoints3D[i].z);
    }

    // @TODO I would like to just update the data, not set the attribute everytime, but I need to know the number of bones in advance
    this.geometry.setAttribute( 'position', new Float32BufferAttribute( pointsVertices, 3 ) );
    // this.geometry.attributes.position.needsUpdate = true;
  }
}

export default PointsHelper

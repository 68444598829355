import KalmanFilter from 'kalmanjs';
import {Vector3} from 'three';

import CONFIG from '../config.js';
import {valueFromThreejsToCentered} from '../helpers.js';
import AbstractTrackingDataTweaker from './AbstractTrackingDataTweaker.js';

const DEPTH_SMOOTHING = true;
const HALF_MOVE_RANGE = CONFIG.HAND_MOVEMENT_RANGE * 0.5;
const VEC3 = new Vector3();

// NOTE: I wanted to use multiple fingers, but in the end only one suffice apparently
const BONES_USED_FOR_DEPTH = [
  // 'pinky_finger_mcp',
  // 'ring_finger_mcp',
  // 'middle_finger_mcp',
  'index_finger_mcp',
  // 'thumb_mcp',
];

const BONES_RANGE_CENTERS = {
  // pinky_finger_mcp: ??,
  // ring_finger_mcp: ??,
  // middle_finger_mcp: ??,
  index_finger_mcp: 0.5,
  // thumb_mcp: ??,
};

// NOTE: this limit is used to make sure it's easier to "touch" the content
// without pushing the range farther and farther until it's less easy to touch the content
const BONES_RANGE_LIMITS = {
  // pinky_finger_mcp: ??,
  // ring_finger_mcp: ??,
  // middle_finger_mcp: ??,
  index_finger_mcp: 0.45,
  // thumb_mcp: ??,
};

/**
 * Estimate hand Z depth from the 2D hands data
 */
class DepthEstimator extends AbstractTrackingDataTweaker {

  constructor(handRig) {
    super();

    this._handRig = handRig;
    this._pointsNamed = {};

    this._smoothDepth = new KalmanFilter({
      R: 0.9,
    });

    this.zFactor = 0;
  }

  /**
  * Handle incoming data
  */
  handleTrackingInput(handData) {
    if (!handData.keypoints) {
      return;
    }

    const riggingProfile = this._handRig.riggingProfile;

    let boneName, point2D, point3D;


    // store points in named object
    // ---------------------------------------------------
    for (let i = 0; i < handData.keypoints.length; i++) {
      point2D = handData.keypoints[i];
      point3D = handData.keypoints3D[i];
      boneName = point2D.name;

      this._pointsNamed[boneName] = {
        x: point2D.x,
        y: point2D.y,
        z: valueFromThreejsToCentered(point3D.z), // @TODO I need to get Z too to fix calculations when hand is tilted forward or backward
      };
    }


    // calculate the scale difference
    // ---------------------------------------------------
    let parentName, pointA, pointB, lengthCurrent, lengthCenter, lengthMin, lengthMax, scaleAvg = 0, scaleCount = 0;
    for (let i = 0; i < BONES_USED_FOR_DEPTH.length; i++) {
      boneName = BONES_USED_FOR_DEPTH[i];
      parentName = riggingProfile.boneParents[boneName];
      pointA = this._pointsNamed[boneName];
      pointB = this._pointsNamed[parentName];
      // lengthCenter = riggingProfile.boneLengths[boneName];
      lengthCenter = Math.min(BONES_RANGE_LIMITS[boneName], BONES_RANGE_CENTERS[boneName]);
      lengthMin = lengthCenter * 0.6;
      lengthMax = lengthCenter * 1.4;

      VEC3.copy(pointA);
      lengthCurrent = Math.min(1, VEC3.distanceTo(pointB));

      if (lengthCurrent < lengthMin) {
        BONES_RANGE_CENTERS[boneName] *= 0.95;
      }
      if (lengthCurrent > lengthMax) {
        BONES_RANGE_CENTERS[boneName] *= 1.05;
      }

      // @TODO the more small a length is, the bigger the IRL distance is (because of perspective)
      // our calculation does not account for that, therefore if the range center moves farter away, it will feel like
      // movements of the real hand on Z axis has less impact on the 3D hand...
      // to fix that properly I would need access to the FOV of the camera itself, which I don't, so I would have to hardcode one that is ok in most cases

      scaleAvg += (lengthCurrent - lengthMin) / (lengthMax - lengthMin);
      scaleCount++;
    }
    scaleAvg = Math.min(1, Math.max(0, scaleAvg / scaleCount));


    // estimate depth
    // ---------------------------------------------------
    let decalZ = scaleAvg;

    if (DEPTH_SMOOTHING) {
      this._smoothDepth.filter(decalZ);
      decalZ = this._smoothDepth.x;
    }

    this.zFactor = decalZ;

    decalZ *= CONFIG.HAND_MOVEMENT_RANGE;
    decalZ -= HALF_MOVE_RANGE;


    // apply depth to 3D points
    // ---------------------------------------------------
    for (let i = 0; i < handData.keypoints3D.length; i++) {
      handData.keypoints3D[i].z += decalZ;
    }
  }
}

export default DepthEstimator;

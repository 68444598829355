import {IS_MOBILE} from '../env.js';
import CONFIG from '../config.js';

import StatsPanel from '../debug/StatsPanel.js';
import LogsManager from '../managers/LogsManager.js';
import LoadingManager from '../managers/LoadingManager.js';
import UserCamera from '../medias/UserCamera.js';
import CoreTracking from '../core/CoreTracking.js';
// import PersonRigOneHanded from '../rigging/PersonRigOneHanded.js';
import PersonRigTwoHanded from '../rigging/PersonRigTwoHanded.js';
import FakeTrackingDataFeeder from '../debug/FakeTrackingDataFeeder.js';
import AbstractThreeJsApp from './AbstractThreeJsApp.js'

/**
 * The default app class for our project
 */
class MetaHandApp extends AbstractThreeJsApp {
  constructor() {
    // @TODO temporary !!
    const domContainer = document.getElementById('main-experience');
    // domContainer.classList.add('metahand-container');

    super(domContainer);

    this._domStats = document.createElement('div');
    this._domStats.setAttribute('id', 'metahand-stats');

    this._domPoses = document.createElement('div');
    this._domPoses.setAttribute('id', 'metahand-poses');

    this._domVersion = document.createElement('div');
    this._domVersion.setAttribute('id', 'metahand-version');

    this._domLoader = document.getElementById('metahand-loader');
    this._domLoader.style.visibility = 'hidden';
    // this._domLoader = document.createElement('div');
    // this._domLoader.innerText = 'Loading...';
    // this._domLoader.setAttribute('id', 'metahand-loader');
    // this._domLoader.setAttribute('class', 'full-message');

    domContainer.appendChild(this._domStats);
    domContainer.appendChild(this._domPoses);
    domContainer.appendChild(this._domVersion);
    domContainer.appendChild(this._domLoader);

    UserCamera.videoPlayer.injectInDOM(domContainer);
    LoadingManager.setDomContainer(this._domLoader);
    LogsManager.setDomContainer(this._domVersion);

    if (CONFIG.DEBUG_FPS_STATS) {
      this._domStats.appendChild(StatsPanel.domElement);
    }

    this._personRig = null;
    this._cameraLoadHandle = null;
    this._trackingLoadHandle = null;
    this._cameraTrackingStarted = false;
  }

  async startApp(sceneClass) {
    this.initializeScene(sceneClass);
    this.initializeHandsRig();

    if (CONFIG.QUICK_DEBUG_MODE) {
      this.initializeQuickDebugMode();
    } else {
      await this.waitForUserReady();

      this._domLoader.style.visibility = 'visible';

      this.preloadCameraTracking();

      this.currentScene.onUserReady();

      // note: this would init everything else, now
      // await this.initializeCameraVideo();
      // await this.startCameraTracking();
    }
  }

  registerHandPoseDetector(handPoseDetector) {
    const posesDomContainer = document.createElement('span');
    this._domPoses.appendChild(posesDomContainer);

    handPoseDetector.on('poses-changed', (poses) => {
      posesDomContainer.style.transform = 'scaleX('+ (handPoseDetector._handRig._isInverted?'-1':'1') +')';
      posesDomContainer.innerHTML = poses.map((pose) => { return pose.emoji; });
    });
  }

  waitForUserReady() {
    return new Promise((resolve, reject) => {
      if (CONFIG.REQUEST_USER_ACTION) {
        const action = IS_MOBILE ? 'Tap' : 'Click';
        const message = action + ' to start';
        const details = CONFIG.MATURE_CONTENT ? '<b>WARNING</b><br>This website is for adults only!<br>You must be 18&nbsp;years old or older to enter.' : '';
        this.requestUserAction(message, details).then(resolve);
      } else {
        resolve();
      }
    });
  }

  initializeScene(sceneClass) {
    this.setCurrentScene( new sceneClass() );
    this.startRenderLoop();

    if (CONFIG.DEBUG_ORBIT_CONTROLS) {
      this.enableOrbitControls();
    }

    if (CONFIG.DEBUG_GENERIC_HELPERS) {
      this.currentScene.enableGenericHelpers();
    }
  }

  initializeHandsRig() {
    // NOTE : it's much smoother to always have 1 separated hand for each handedness,
    // this way it does not move erratically when handedness changes on the fly
    this._personRig = new PersonRigTwoHanded(this.currentScene);
    // this._personRig = CONFIG.BOTH_HANDS_ENABLED ? new PersonRigTwoHanded(this.currentScene) : new PersonRigOneHanded(this.currentScene);
  }

  initializeCameraVideo() {
    return new Promise((resolve, reject) => {
      if (UserCamera.isReady()) {
        resolve();
        return;
      }

      if (!this._cameraLoadHandle) {
        this._cameraLoadHandle = LoadingManager.startLoading('user-camera');
      }

      const attemptCameraAccess = () => {
        return UserCamera.initializeUserCamera().then(() => {
          // set tracking loop speed
          const targetTrackingFps = Math.min(CONFIG.MAX_TRACKING_FPS, UserCamera.videoFrameRate); // note: dont track faster that the video FPS !
          CoreTracking.trackingLoop.fps = targetTrackingFps;

          // set rendering loop to match tracking speed on mobile, to save on performance a bit
          // if (IS_MOBILE) {
          //   this.renderLoop.fps = targetTrackingFps;
          // }

          this.currentScene.onUserCameraReady();

          this._cameraLoadHandle.stopLoading();
        });
      }

      const attemptOrRetry = () => {
        attemptCameraAccess().then(resolve).catch((err) => {
          this.requestUserAction((err.message || err.name || 'Unknown error') + '<br>Try again').then(attemptOrRetry);
        });
      };

      attemptOrRetry();
    });
  }

  preloadCameraTracking() {
    if (!this._trackingLoadHandle) {
      this._trackingLoadHandle = LoadingManager.startLoading('hands-tracking');
    }

    return CoreTracking.initialize().then(() => {
      this._trackingLoadHandle.stopLoading();
    });
  }

  startCameraTracking() {
    if (this._cameraTrackingStarted) {
      return;
    }

    this._cameraTrackingStarted = true;

    CoreTracking.on('tracking-data-received', this._personRig.handleHandsTrackingInput.bind(this._personRig));
    CoreTracking.startTrackingLoop();

    this.currentScene.onTrackingStarted(this._personRig);
  }

  initializeQuickDebugMode() {
    // quickfix because no video will ever be loaded here
    UserCamera.videoPlayer.videoWidth = 640;
    UserCamera.videoPlayer.videoHeight = 480;

    new FakeTrackingDataFeeder(this._personRig.handleHandsTrackingInput.bind(this._personRig));
  }

  requestUserAction(message = 'Click here', details = '') {
    return new Promise((resolve, reject) => {
      const startButton = document.createElement('button');
      if (details) {
        startButton.innerHTML += '<span class="txt-details">'+ details +'</span>';
      }
      startButton.innerHTML += '<span class="txt-button">'+ message +'</span>';
      startButton.className = 'user-action-btn full-message';
      this._domContainer.appendChild(startButton);

      startButton.addEventListener('click', () => {
        this._domContainer.removeChild(startButton);
        resolve();
      });
    });
  }

  showUserHtml(elem) {
    elem.classList.add('full-message');
    elem.style.opacity = 0;
    this._domContainer.appendChild(elem);
    setTimeout(() => { elem.style.opacity = 1; }, 10);

    return {
      destroy: () => {
        elem.style.opacity = 0;
        setTimeout(() => {
          this._domContainer.removeChild(elem);
        }, 750);
      },
    };
  }

  showUserMessage(message = '') {
    const div = document.createElement('div');
    div.innerHTML = message;

    return this.showUserHtml(div);
  }
}

export default MetaHandApp;

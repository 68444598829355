/**
 * Singleton class used to keep track of what is loading
 */
class LoadingManager {
  constructor() {
    this._loadingsBegun = 0;
    this._loadingsEnded = 0;
    this._loadedAmountNormalized = 1;
    this._loadedAmountPercentage = 100;
    this._domView = null;
  }

  isFullyLoaded() {
    return this._loadedAmountNormalized === 1;
  }

  setDomContainer(domElem) {
    this._domView = domElem;
  }

  startLoading(ident = '') {
    ident += '_' + ++this._loadingsBegun;
    // console.log('start', ident);
    this._recalculateStats();

    return {
      stopLoading: () => {
        this._loadingsEnded += 1;
        // console.log('stop', ident);
        this._recalculateStats();
      },
    };
  }

  _recalculateStats() {
    this._loadedAmountNormalized = this._loadingsEnded / this._loadingsBegun;
    this._loadedAmountPercentage = this._loadedAmountNormalized * 100;

    this._domView.innerHTML = 'Loading ' + Math.round(this._loadedAmountPercentage) + '%';
    // this._domView.innerText = 'Loading ' + this._loadingsEnded + ' / ' + this._loadingsBegun;
    this._domView.style.opacity = this._loadedAmountNormalized < 1 ? 1 : 0;
  }
}

export default new LoadingManager();

import {
  TextureLoader,
} from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

import LoadingManager from './LoadingManager.js';

/**
 * Singleton class to manage assets
 */
class AssetsManager {

  loadTextureFile(filePath) {
    return new TextureLoader().load(filePath);
  }

  loadGltfFile(filePath) {
    const loadHandle = LoadingManager.startLoading('asset-gltf');

    // @TODO I guess this will load the file many times if called many times, implement caching here
    return new GLTFLoader().loadAsync(filePath).then((result) => {
      loadHandle.stopLoading();
      return result;
    });
  }
}

export default new AssetsManager();

import FpsTracker from '../utils/FpsTracker.js'

/**
 * Singleton class to handle fps tracking of different parts of the app
 */
class FpsManager {
  constructor() {
    this.handTrackingFpsTracker = new FpsTracker();
  }
}

export default new FpsManager();

import CONFIG from '../config.js';

import UserCamera from '../medias/UserCamera.js';
import AbstractTrackingDataTweaker from './AbstractTrackingDataTweaker.js';

const HALF_MOVE_RANGE = CONFIG.HAND_MOVEMENT_RANGE * 0.5;

const _decal3D = {x: 0, y: 0, z: 0};

/**
 * Takes incoming hand tracking data and sanitize it before it's used upstream
 */
class TrackingDataSanitizer extends AbstractTrackingDataTweaker {

  /**
  * Generic data cleanup
  */
  handleTrackingInput(handData) {
    const inverted = handData.handedness === 'Left';
    const handPoints3D = handData.keypoints3D;
    const handPoints2D = handData.keypoints;

    // reset decal
    _decal3D.x = _decal3D.y = _decal3D.z = 0;

    // center at wrist
    if (CONFIG.WRIST_CENTERED) {
      const wristPoint3D = handPoints3D[0]; // note: assuming wrist is always the first bone

      _decal3D.x = wristPoint3D.x;
      _decal3D.y = wristPoint3D.y;
      _decal3D.z = wristPoint3D.z;
    }

    // if (CONFIG.PUT_YOUR_HANDS_UP) {
    //   _decal3D.y += 0.03;
    // }

    // place at desired depth
    const depthCenter = CONFIG.FRONT_FACING_CAMERA ? CONFIG.HAND_DEPTH_CENTER : -CONFIG.HAND_DEPTH_CENTER;
    _decal3D.z += HALF_MOVE_RANGE * depthCenter;

    let point;

    // handle 3D points
    for (let i = 0; i < handPoints3D.length; i++) {
      point = handPoints3D[i];

      // apply decal
      point.x -= _decal3D.x;
      point.y -= _decal3D.y;
      point.z -= _decal3D.z;

      // negate data (because it came in upside down)
      point.x = -point.x;
      point.y = -point.y;
      point.z = -point.z;

      // invert 1 axis when hand is inverted
      if (inverted) {
        point.x = - point.x;
      }
    }

    // handle 2D points
    // NOTE: convert data into a representation ranging from -1 to 1, where 0 is at the center
    // this data represents the normalized position inside the user's camera feed rectangle
    for (let i = 0; i < handPoints2D.length; i++) {
      point = handPoints2D[i];

      point.x /= UserCamera.videoPlayer.videoWidth;
      point.y /= UserCamera.videoPlayer.videoHeight;

      point.x = (point.x - 0.5) * 2;
      point.y = (point.y - 0.5) * 2;

      point.x = -point.x;
      point.y = -point.y;

      point.x = Math.min(1, Math.max(-1, point.x));
      point.y = Math.min(1, Math.max(-1, point.y));
    }
  }
}

export default TrackingDataSanitizer;

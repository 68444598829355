import {
  Scene,
  Group,
  PerspectiveCamera,
  DirectionalLight,
  HemisphereLight,
  AxesHelper,
  GridHelper,
} from 'three';

import CONFIG from '../../config.js';
import CONTEXT from '../../context.js';
import {posFromCenteredToThreejs} from '../../helpers.js';
import ViewportManager from '../../managers/ViewportManager.js';
import FireworkEffect from '../particles/FireworkEffect.js';
import ShadowPlane from '../view/ShadowPlane.js';

const INITIAL_DEPTH_CENTER = CONFIG.HAND_DEPTH_CENTER;
const HALF_MOVE_RANGE = CONFIG.HAND_MOVEMENT_RANGE * 0.5;

/**
 * Wrap and extend the default threejs scene
 */
class AbstractScene extends Scene {
  constructor() {
    super(...arguments);

    // reset depth center
    CONFIG.HAND_DEPTH_CENTER = INITIAL_DEPTH_CENTER;

    this._fov = 45;
    // this._cameraBackAmount = (90 / this._fov); // note: this math is not 100% right, so I set it manually for now :)
    this._cameraBackAmount = 2.44; // note: this math is not 100% right, so I set it manually for now :)
    this._maximumPerspectiveCompensation = 0.28975265; // this is how much smaller farther objects will look at the end of the movement range (it will be different if I change the fov)

    // main camera
    this.mainCamera = new PerspectiveCamera(this._fov, 1, 0.01, 100);
    // this.mainCamera.position.y = CONFIG.HAND_MOVEMENT_RANGE * -0.125;

    // if (CONFIG.WRIST_CENTERED) {
    //   this.mainCamera.position.y += 0.1 * CONFIG.HAND_MOVEMENT_RANGE;
    // }

    // @TODO the camera z is set up to make sure the movement zone height fits perfectly inside the rendered scene (when the hand is a 0 depth)
    // the camera depth might be different when the hand will have a differet depth, maybe we will need to slide the camera along the z axis to follow the hand ?
    this.mainCamera.position.z = this._cameraBackAmount * HALF_MOVE_RANGE;

    // containers
    this.mainContainer = new Group(); // @TODO this container might not have much use now (added it in an attempt to scale everything from it, but I changed my approach)
    this.handsContainer = new Group(); // @TODO this container might not have much use now
    this.websiteScrollContainer = new Group();

    // main light
    this.mainLight = new DirectionalLight(0xffffff, 1);
    this.mainLight.position.set(10, 10, 10);
    this.mainLight.target.position.set(1, -10, 0);

    if (CONFIG.SHADOW_CASTING_ON) {
      const shadowRadius = 1;
      this.mainLight.castShadow = true;
      this.mainLight.shadow.mapSize.width = 1024;
      this.mainLight.shadow.mapSize.height = 1024;
      this.mainLight.shadow.camera.left = -shadowRadius;
      this.mainLight.shadow.camera.right = shadowRadius;
      this.mainLight.shadow.camera.bottom = -shadowRadius;
      this.mainLight.shadow.camera.top = shadowRadius;
      this.mainLight.shadow.camera.near = 0.5;
      this.mainLight.shadow.camera.far = 20;
      this.mainLight.shadow.bias = -0.0005;
    }

    // hemisphere light
    this.hemisphereLight = new HemisphereLight( 0xffffff, 0x111111, 0.75 );
    this.hemisphereLight.position.y = 1;

    // add all
    this.add(
      this.mainCamera,
      this.mainContainer,
    );
    this.mainContainer.add(
      this.handsContainer,
      this.websiteScrollContainer,
      this.mainLight,
      this.hemisphereLight
    );

    // listeners
    ViewportManager.on('viewport-resized', this._onViewportResized.bind(this));
    this._onViewportResized();
  }

  createShadowPlane() {
    const plane = new ShadowPlane(CONFIG.HAND_MOVEMENT_RANGE * 4);
    plane.position.z = -((CONFIG.HAND_DEPTH_CENTER * HALF_MOVE_RANGE) + (HALF_MOVE_RANGE * CONFIG.FINGER_PENETRATION_MULT));

    this.mainContainer.add(plane);
  }

  enableGenericHelpers() {
    const range = CONFIG.HAND_MOVEMENT_RANGE;
    const divisions = 8;
    const color = 0x88ff00;

    const gridHelperFloor = new GridHelper(range, divisions, color, color);
    // gridHelperFloor.receiveShadow = CONFIG.SHADOW_CASTING_ON;
    // gridHelperFloor.castShadow = CONFIG.SHADOW_CASTING_ON;
    gridHelperFloor.position.y = range * -0.5;

    const gridHelperWall = new GridHelper(range, divisions, color, color);
    // gridHelperWall.receiveShadow = CONFIG.SHADOW_CASTING_ON;
    // gridHelperWall.castShadow = CONFIG.SHADOW_CASTING_ON;
    gridHelperWall.rotation.x = Math.PI * -0.5;
    gridHelperWall.position.z = range * -0.5;

    const gridHelperCenter = new GridHelper(range, divisions, 0xff0000, 0xff0000);
    gridHelperCenter.rotation.x = Math.PI * -0.5;

    const axesHelper = new AxesHelper((range * 0.5) / (divisions * 0.5));
    axesHelper.position.y = range * -0.5;
    axesHelper.position.y += 0.001;

    const grids = [
      gridHelperFloor,
      gridHelperWall,
      gridHelperCenter,
    ];

    this.mainContainer.add(
      ...grids,
      axesHelper
    );

    if (CONFIG.LEGACY_CENTERING) {
      const resizeGrids = () => {
        for (let i = 0; i < grids.length; i++) {
          grids[i].scale.x = CONTEXT.APP.viewportRatio;
        }
      };

      ViewportManager.on('viewport-resized', resizeGrids);
      resizeGrids();
    }
  }

  startParty() {
    this._partyInterval = setInterval(() => {
      const effect = new FireworkEffect(16);

      effect.position.x = (Math.random() - 0.5) * 2;
      effect.position.y = (Math.random() - 0.5) * 2;
      effect.position.z = (Math.random() - 0.5) * 2;
      // effect.position.z = -1;
      posFromCenteredToThreejs(effect.position);

      this.mainContainer.add(effect);
    }, 750);
  }

  stopParty() {
    if (this._partyInterval) {
      clearInterval(this._partyInterval);
      delete this._partyInterval;
    }
  }

  onUserReady() {
    // ...
  }

  onUserCameraReady() {
    // ...
  }

  onTrackingStarted(personRig) {
    // ...
  }

  _onViewportResized() {
    this.mainCamera.aspect = CONTEXT.APP.viewportRatio;

    if (!CONFIG.LEGACY_CENTERING) {
      if (CONTEXT.APP.viewportWidth > CONTEXT.APP.viewportHeight) {
        this.mainCamera.zoom = CONTEXT.APP.viewportWidth / CONTEXT.APP.viewportHeight;
      } else {
        this.mainCamera.zoom = 1;
      }
    }

    this.mainCamera.updateProjectionMatrix();
  }
}

export default AbstractScene;

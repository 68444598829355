import {Vector3} from 'three';

import CONFIG from '../config.js';
import CONTEXT from '../context.js';
import {valueFromCenteredToThreejs} from '../helpers.js';
import UserCamera from '../medias/UserCamera.js';
import AbstractTrackingDataTweaker from './AbstractTrackingDataTweaker.js';

const HALF_MOVE_RANGE = CONFIG.HAND_MOVEMENT_RANGE * 0.5;
const REACH_RANGE_FACTOR = 1.25;

const BONES_USED_FOR_DEPTH = [
  // 'pinky_finger_mcp',
  // 'ring_finger_mcp',
  // 'middle_finger_mcp',
  'index_finger_mcp',
  // 'thumb_mcp',
];

// let debugDecal = 0;

/**
 * Takes incoming hand tracking data and mix 2D data with the 3D data
 *
 * @TODO need to find a way to ensure bone length consistency before calculating depth, and without breaking fingers touching...
 * NOTE: I think if I would scale bones between top to wrist, maybe I could fix bones inconsistent length while keeping fingers touching !!
 */
class TwoDimensionalDataMixer extends AbstractTrackingDataTweaker {

  constructor(handRig) {
    super();

    this._handRig = handRig;
    this._boneVectors = {};

    // this._debug = document.createElement('div');
    // this._debug.innerText = '';
    // this._debug.style.position = 'fixed';
    // this._debug.style.top = (debugDecal++ * 20) + 'px';
    // this._debug.style.right = '0px';
    // this._debug.style.padding = '5px 10px';
    // document.body.appendChild(this._debug);
  }

  /**
  * Handle incoming data
  * @TODO maybe this could all be optimized/simplified more ?
  */
  handleTrackingInput(handData) {
    if (!handData.keypoints) {
      return;
    }

    const direction = this._handRig._isInverted ? -1 : 1;
    const viewportRatio = CONTEXT.APP.viewportRatio;
    const videoRatio = UserCamera.videoPlayer.videoRatio;
    const riggingProfile = this._handRig.riggingProfile;

    let point2D, point3D, boneName, pointA, pointB;

    // mix 2D data with 3D data and store it
    // ---------------------------------------------------
    for (let i = 0; i < handData.keypoints3D.length; i++) {
      point2D = handData.keypoints[i];
      point3D = handData.keypoints3D[i];
      boneName = point2D.name;

      if (typeof this._boneVectors[boneName] === 'undefined') {
        this._boneVectors[boneName] = new Vector3();
      }
      pointA = this._boneVectors[boneName];

      pointA.x = valueFromCenteredToThreejs(point2D.x, 'x') * direction;
      pointA.y = valueFromCenteredToThreejs(point2D.y, 'y');
      pointA.z = point3D.z;
    }

    // calculate the scale difference
    // ---------------------------------------------------
    let parentName, lengthCurrent, lengthExpected, scaleAvg = 0, scaleCount = 0;
    for (let i = 0; i < handData.keypoints3D.length; i++) {
      point3D = handData.keypoints3D[i];
      boneName = point3D.name;
      pointA = this._boneVectors[boneName];
      lengthExpected = riggingProfile.boneLengths[boneName];
      parentName = riggingProfile.boneParents[boneName];

      if (lengthExpected && parentName && BONES_USED_FOR_DEPTH.indexOf(boneName) !== -1) {
        pointB = this._boneVectors[parentName];
        lengthCurrent = pointA.distanceTo(pointB);

        scaleAvg += lengthCurrent / lengthExpected;
        scaleCount++;
      }
    }
    scaleAvg = 1 / (scaleAvg / scaleCount);

    // apply our data mix to 3D points
    // ---------------------------------------------------
    for (let i = 0; i < handData.keypoints3D.length; i++) {
      point3D = handData.keypoints3D[i];
      boneName = point3D.name;
      pointA = this._boneVectors[boneName];

      point3D.x = pointA.x * scaleAvg * REACH_RANGE_FACTOR;
      point3D.y = pointA.y * scaleAvg * REACH_RANGE_FACTOR;
      point3D.z = pointA.z;

      if (!CONFIG.FRONT_FACING_CAMERA) {
        point3D.x = - point3D.x;
      }

      // compensate stretching caused by ratio difference between viewport and camera feed
      // ---------------------------------------------------
      if (CONFIG.LEGACY_CENTERING) {
        const ratioA = viewportRatio / videoRatio;
        const ratioB = videoRatio / viewportRatio;

        point3D.y *= ratioA;
        if (viewportRatio < videoRatio) {
          point3D.y *= ratioB;
          point3D.x *= ratioB;
        }
      }
    }
  }
}

export default TwoDimensionalDataMixer;

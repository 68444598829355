import {
  ColorManagement,
  WebGLRenderer,
  PCFSoftShadowMap,
  sRGBEncoding,
  ACESFilmicToneMapping,
} from 'three';

ColorManagement.legacyMode = false;

import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'

import CONFIG from '../config.js';
import ViewportManager from '../managers/ViewportManager.js';
import StatsPanel from '../debug/StatsPanel.js';
import {createUpdatesLoop} from '../utils/updates_loop.js';

const MIXINS = [
  HasEventsMixin,
];

/**
 * The spine of a three.js app
 *
 * May emit those events :
 * - update-loop   = when an update tick happens
 */
class AbstractThreeJsApp {
  constructor(domContainer) {
    MixinsComposer.construct(this, MIXINS);

    this._domContainer = domContainer;

    this.renderLoop = createUpdatesLoop(this._onRender.bind(this), 60);

    // pause loop when not visible
    this.renderLoop.isPaused = !ViewportManager.viewportVisible;
    ViewportManager.on('visibility-changed', (visible) => {
      this.renderLoop.isPaused = !visible;
    });

    // renderer
    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
      powerPreference: 'high-performance',
    });

    // this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = sRGBEncoding;
    this.renderer.toneMapping = ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1.2;

    // canvas
    this.canvas = this.renderer.domElement;
    // this.canvas.style.imageRendering = 'pixelated';
    this.canvas.classList.add('app-canvas');

    if (CONFIG.SHADOW_CASTING_ON) {
      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = PCFSoftShadowMap;
    }

    // others
    this.controls = null;
    this.currentScene = null;

    this._domContainer.appendChild(this.renderer.domElement);

    this._scrollGroupNeedUpdate = false;

    // listeners
    ViewportManager.on('viewport-resized', this._onViewportResized.bind(this));
    this._onViewportResized();
    ViewportManager.on('viewport-scrolled', this._onViewportScrolled.bind(this));
  }

  setCurrentScene(scene) {
    this.currentScene = scene;
    this._scrollGroupNeedUpdate = true;
  }

  startRenderLoop() {
    this.renderer.setAnimationLoop(this.renderLoop.loop);
  }

  enableOrbitControls() {
    this.controls = new OrbitControls(this.currentScene.mainCamera, this.renderer.domElement);
    this.controls.target.set(0, this.currentScene.mainCamera.position.y, 0);
    this.controls.enableDamping = true;
  }

  _updateSceneScrollGroup() {
    const bb = this._domContainer.getBoundingClientRect();
    const centerOfViewport = ViewportManager.viewportHeight * 0.5;
    const centerOfApp = (bb.height * 0.5) + this._domContainer.offsetTop - window.scrollY;
    const realSceneHeight = CONFIG.LEGACY_CENTERING ? this.viewportHeight : Math.max(this.viewportHeight, this.viewportWidth);
    const verticalAdjustments = (centerOfViewport - centerOfApp) / realSceneHeight;

    // NOTE: if an object is in this container but farther than the center, it will appear as if it is not following the website properly
    // this is because the website does not have perspective while the 3D scene has... which changes how distances appears
    this.currentScene.websiteScrollContainer.position.y = verticalAdjustments * CONFIG.HAND_MOVEMENT_RANGE;
  }

  _onRender(deltaTime) {
    if (CONFIG.DEBUG_FPS_STATS) {
      StatsPanel.begin();
    }

    this.emit('update-loop', deltaTime);

    if (this.controls) {
      this.controls.update();
    }

    if (this.currentScene) {
      if (CONFIG.GOD_MODE && this._scrollGroupNeedUpdate) {
        // @TODO even if I do this before render, there is still a delay that causes visual problems (UI does not follow webpage closely)
        this._updateSceneScrollGroup();
        this._scrollGroupNeedUpdate = false;
      }

      this.renderer.render(this.currentScene, this.currentScene.mainCamera);
    }

    if (CONFIG.DEBUG_FPS_STATS) {
      StatsPanel.end();
    }
  }

  _onViewportResized() {
    this.viewportWidth = this.canvas.offsetWidth;
    this.viewportHeight = this.canvas.offsetHeight;
    this.viewportRatio = this.viewportWidth / this.viewportHeight;

    this.renderer.setSize(
      this.viewportWidth * CONFIG.RENDERER_RESOLUTION,
      this.viewportHeight * CONFIG.RENDERER_RESOLUTION
    );

    this._scrollGroupNeedUpdate = true;
  }

  _onViewportScrolled() {
    this._scrollGroupNeedUpdate = true;
  }
}

MixinsComposer.extend(AbstractThreeJsApp).with(...MIXINS);

export default AbstractThreeJsApp;

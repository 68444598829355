import _forEach from 'lodash/forEach.js';

import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'

const MIXINS = [
  HasEventsMixin,
];

/**
 * A values storage where every changes are debounced by the specified delay
 *
 * May emit those events :
 * - value-changed       = after a value has changed
 */
class ValuesDebouncer {
  constructor(values = {}, waitTime = 0) {
    MixinsComposer.construct(this, MIXINS);

    this._waitTime = waitTime;
    this._internalValues = {};
    this._pendingChanges = {};

    // initial values
    _forEach(values, (value, key) => {
      this._registerValue(key, value);
    });
  }

  _registerValue(key, defaultValue) {
    this._internalValues[key] = defaultValue;

    Object.defineProperty(this, key, {
      get: () => {
        return this._internalValues[key];
      },
      set: (nv) => {
        const currentValue = this._internalValues[key];
        const pendingChange = this._pendingChanges[key];

        // when no change pending
        if (!pendingChange) {
          if (currentValue !== nv) {
            this._startPendingChange(key, nv);
          }
        }

        // when pending change
        else {
          if (pendingChange.value === nv) {
            return;
          } else {
            if (currentValue !== nv) {
              this._stopPendingChange(key);
              this._startPendingChange(key, nv);
            }
          }
        }
      },
    });
  }

  _startPendingChange(key, nv) {
    if (this._waitTime > 0) {
      this._pendingChanges[key] = {
        value: nv,
        timeout: setTimeout(this._applyPendingChange.bind(this, key), this._waitTime),
      };
    } else {
      this._setInternalValue(key, nv);
    }
  }

  _applyPendingChange(key) {
    this._setInternalValue(key, this._pendingChanges[key].value);
    this._stopPendingChange(key);
  }

  _setInternalValue(key, nv) {
    const ov = this._internalValues[key];

    this._internalValues[key] = nv;

    this.emit('value-changed', key, nv, ov);
  }

  _stopPendingChange(key) {
    clearTimeout(this._pendingChanges[key].timeout);
    delete this._pendingChanges[key];
  }
}

MixinsComposer.extend(ValuesDebouncer).with(...MIXINS);

export default ValuesDebouncer;

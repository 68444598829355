import _defaults from 'lodash/defaults.js';
import _round from 'lodash/round.js';

import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'
import HasPlaylistFeatureMixin from './mixins/HasPlaylistFeature.js'

import CONFIG from '../config.js';
import CONTEXT from '../context.js';
import { getResizeSpecs } from '../helpers.js';
import ViewportManager from '../managers/ViewportManager.js';

const MIXINS = [
  HasEventsMixin,
  HasPlaylistFeatureMixin,
];

const DEFAULT_PARAMS = {
  coverWholeBackground: false,
  flipHorizontal: false,
  autoLoop: false,
  volume: 1, // [0 - 1]
  playbackRate: 1, // [0 - Infinity]
  decalX: 0, // from -1 to 1
  decalY: 0, // from -1 to 1
};

/**
 * Class encapsulating a <video> element also in a wrapper (for css reasons)
 *
 * May emit those events :
 * - video-loaded             = after the video has been loaded
 * - video-timeupdate         = when the video currentTime has been updated
 * - video-started            = when the video started playing
 * - video-ended              = when the video playback as stopped because it reached the end of it
 * - covering-ratio-changed   = after the video covering ratio changed
 */
class VideoPlayer {
  constructor(params = {}) {
    MixinsComposer.construct(this, MIXINS);

    this._containerClasses = ['video-container'];
    this._videoClasses = [];

    this._videoCoveringWidthRatio = 1;
    this._videoCoveringHeightRatio = 1;
    this._videoContentScale = 1;

    // dom elements
    this._containerElem = document.createElement('div');
    this._videoElem = document.createElement('video');
    this._videoElem.setAttribute('playsinline', '');

    this.updateSettings(params);

    this._decalX = this._defaultDecalX;
    this._decalY = this._defaultDecalY;

    this._containerElem.appendChild(this._videoElem);

    // metadatas
    this.videoWidth = 0;
    this.videoHeight = 0;
    this.videoRatio = 1;
    this.videoIsLandscape = true;

    this.hide();

    // events listeners
    this._videoElem.addEventListener('timeupdate', this._onVideoTimeUpdate.bind(this));
    this._videoElem.addEventListener('ended', this._onVideoEnded.bind(this));
  }

  // note: this method does not reset to default, it only update params that are passed here and keep others as they were
  // @TODO would be a lot cleaner to have a state object on which I watch changes and adapt everything from it...
  updateSettings(params = {}) {
    params = _defaults(params, this._lastSettings || {}, DEFAULT_PARAMS);

    this.coverWholeBackground = params.coverWholeBackground;
    this._flipHorizontal = params.flipHorizontal;

    this._defaultDecalX = params.decalX;
    this._defaultDecalY = params.decalY;

    if (params.autoLoop) {
      this._videoElem.setAttribute('loop', '');
    } else {
      this._videoElem.removeAttribute('loop');
    }

    this._videoElem.volume = params.volume;
    this._videoElem.playbackRate = params.playbackRate;

    this._updateStyleClasses();

    this._lastSettings = params;
  }

  injectInDOM(elem) {
    this._updateStyleClasses();
    this._updateStyleProps();
    elem.appendChild(this._containerElem);
  }

  getVideoElem() {
    return this._videoElem;
  }

  setSrcUrl(url) {
    this._videoElem.setAttribute('src', url);
    return this._fetchMetadatas().then(this.show.bind(this));
  }

  setSrcObject(stream) {
    this._videoElem.srcObject = stream;
    return this._fetchMetadatas().then(this.show.bind(this));
  }

  setDecal(decalX, decalY) {
    this._decalX = typeof decalX === 'number' ? decalX : this._defaultDecalX;
    this._decalY = typeof decalY === 'number' ? decalY : this._defaultDecalY;
    this._updateStyleProps();
  }

  resetDecal() {
    this.setDecal(this._defaultDecalX, this._defaultDecalY);
  }

  setHorizontalFlip(flipHorizontal) {
    this._flipHorizontal = flipHorizontal;
    this._updateStyleClasses();
  }

  hide() {
    this._containerElem.style.visibility = 'hidden';
  }

  show() {
    this._containerElem.style.visibility = null;
  }

  isPlaying() {
    return !this._videoElem.paused;
  }

  play() {
    this._videoElem.play();
    this.emit('video-started');
  }

  pause() {
    this._videoElem.pause();
  }

  seek(time = 0) {
    this._videoElem.currentTime = time;
  }

  enableClickToPlayStop() {
    // @TODO temporary mean of starting the video
    if (!CONFIG.VID_SEQUENCE_DEBUG_MODE) {
      // document.querySelector('.app-canvas').style.pointerEvents = 'none';
      this._videoElem.style.cursor = 'pointer';
    }

    // click to play/stop video
    this._videoElem.addEventListener('click', () => {
      if (this.isPlaying()) {
        this.pause();
      } else {
        this.play();
      }
    });
  }

  enableHandSizeControl(scale = 1) {
    if (CONFIG.LEGACY_CENTERING) {
      console.warn('Local hand size control, useful only without LEGACY_CENTERING.');
      return;
    }

    CONFIG.HAND_LOCAL_SCALE_FACTOR = scale;

    if (!this._currentHandSizeControlListener) {
      this._currentHandSizeControlListener = this.on('covering-ratio-changed', this._updateHandLocalScale.bind(this));

      // note: the covering-ratio-changed event get triggered anyway, so we don't need this for now
      // this.once('video-loaded', this._updateHandLocalScale.bind(this, scale));
    }
  }

  _updateHandLocalScale() {
    const containerWidth = this.videoWidth * this._videoContentScale;
    const containerHeight = this.videoHeight * this._videoContentScale;
    const elemWidth = Math.max(CONTEXT.APP.viewportWidth, CONTEXT.APP.viewportHeight);
    const elemHeight = elemWidth;
    const specs = getResizeSpecs(containerWidth, containerHeight, elemWidth, elemHeight, this.coverWholeBackground);

    CONFIG.HAND_LOCAL_SCALE = specs.contentScale * CONFIG.HAND_LOCAL_SCALE_FACTOR;
  }

  _fetchMetadatas() {
    return new Promise((resolve) => {
      this._videoElem.onloadedmetadata = () => {
        this.videoWidth = this._videoElem.videoWidth;
        this.videoHeight = this._videoElem.videoHeight;
        this.videoRatio = this.videoWidth / this.videoHeight;
        this.videoIsLandscape = this.videoWidth > this.videoHeight;

        this._updateVideoCoveringRatio();

        if (!this._videoCoverageRatioListener) {
          // @TODO need to remove this listener if I were to dispose of this instance
          this._videoCoverageRatioListener = ViewportManager.on('viewport-resized', this._updateVideoCoveringRatio.bind(this));
        }

        this.emit('video-loaded');

        resolve();
      };
    });
  }

  _updateStyleClasses() {
    const containerClasses = [].concat(this._containerClasses);
    const videoClasses = [].concat(this._videoClasses);

    videoClasses.push(this.coverWholeBackground ? 'size-cover' : 'size-contain');

    if (this._flipHorizontal) {
      videoClasses.push('invert-horiz');
    }

    this._containerElem.className = containerClasses.join(' ');
    this._videoElem.className = videoClasses.join(' ');
  }

  _updateStyleProps() {
    const decalX = Math.round(((this._decalX * 0.5) + 0.5) * 100);
    const decalY = Math.round(((this._decalY * 0.5) + 0.5) * 100);

    this._videoElem.style.objectPosition = decalX + '% ' + decalY + '%';
  }

  _updateVideoCoveringRatio() {
    const containerWidth = this._containerElem.offsetWidth;
    const containerHeight = this._containerElem.offsetHeight;
    const specs = getResizeSpecs(containerWidth, containerHeight, this.videoWidth, this.videoHeight, this.coverWholeBackground);

    this._latestResizeSpecs = specs;

    if (CONFIG.LEGACY_CENTERING) {
      this._videoCoveringWidthRatio = specs.coveringWidthRatio;
      this._videoCoveringHeightRatio = specs.coveringHeightRatio;

      if (CONFIG.GOD_MODE) {
        // take into account that the video container might be smaller than the full viewport size where hands are
        this._videoCoveringWidthRatio *= containerWidth / CONTEXT.APP.viewportWidth;
        this._videoCoveringHeightRatio *= containerHeight / CONTEXT.APP.viewportHeight;
      }

      this._videoCoveringWidthRatio = _round(this._videoCoveringWidthRatio, 8);
      this._videoCoveringHeightRatio = _round(this._videoCoveringHeightRatio, 8);
    }

    this._videoContentScale = _round(specs.contentScale, 8);

    this.emit('covering-ratio-changed');
  }

  _onVideoTimeUpdate(evt) {
    this.emit('video-timeupdate');
  }

  _onVideoEnded(evt) {
    this.emit('video-ended');
  }
}

MixinsComposer.extend(VideoPlayer).with(...MIXINS);

export default VideoPlayer;

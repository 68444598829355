import {IS_MOBILE} from '../env.js';

/**
 * Singleton class used to centralise errors/messages logging
 */
class LogsManager {
  constructor() {
    this._prefix = 'MetaHand: ';
    this._domView = null;
  }

  setDomContainer(domElem) {
    this._domView = domElem;
  }

  logMessage(message = '') {
    console.log(this._prefix + message);
  }

  logErrorMessage(message = '') {
    if (IS_MOBILE) {
      alert(message);
    } else {
      console.error(this._prefix + message);
    }
  }

  logError(error = '') {
    this.logErrorMessage(error.message || error.name || 'An unknown error occured.');
  }

  logAppVersion(versionNumber) {
    const ident = 'v'+ versionNumber +'-'+ (IS_MOBILE ? 'mobile' : 'desktop');
    this._domView.innerText = ident;
    this.logMessage(ident);
  }
}

export default new LogsManager();

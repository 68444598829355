import {
  Vector3,
  Triangle,
  Object3D,
} from 'three';

const _handRotTriangle = new Triangle();
const _handRotNormal = new Vector3();
const _handRotDummyObj = new Object3D();
// _handRotDummyObj.rotation.reorder('ZXY');

/**
 * Calculates the hand rotation from the lookAt method (subject to gimbal lock problems)
 */
class HandRotLookAtStrategy {
  constructor(riggingStrategy) {
    this._riggingStrategy = riggingStrategy;

    this._isUpward = true; // pointing toward the sky or the floor
  }

  estimateHandRotationZ() {
    const joints = this._riggingStrategy._joints;

    this._isUpward = joints.wrist.y < joints.index_finger_mcp.y && joints.wrist.y < joints.pinky_finger_mcp.y;

    // _handRotTriangle.set(joints.wrist, joints.pinky_finger_mcp, joints.index_finger_mcp);
    _handRotTriangle.set(joints.wrist, joints.index_finger_mcp, joints.pinky_finger_mcp);
    _handRotTriangle.getNormal(_handRotNormal);

    // @TODO inverting the up.y does not fix the rotation properly when the hand is horizontal...
    _handRotDummyObj.up.y = this._isUpward ? 1 : -1;
    _handRotDummyObj.lookAt(_handRotNormal);
    _handRotDummyObj.rotateX(Math.PI * 0.5);

    return - _handRotDummyObj.rotation.z;
  }
}

export default HandRotLookAtStrategy;

import CONFIG from '../config.js';
import FpsManager from '../managers/FpsManager.js';
import KalmanFilter2D from '../kalman/KalmanFilter2D.js';
import KalmanFilter3D from '../kalman/KalmanFilter3D.js';

const getKalmanQfromFps = function(fps) {
  // very approximative adaptation to the current fps
  return 5 / (30 / fps);
};

const KALMAN_SETTINGS = {
  R: 0.9,
  Q: getKalmanQfromFps(CONFIG.MAX_TRACKING_FPS),
};

/**
 * Class responsible for smoothing data comming from hand tracking backend (using Kalman Filter)
 *
 * @TODO problems associated with this kalman approach:
 * - smoothing is not directly tied to delta time (therefore inconsistant on different frame rates)
 * - a quick 180 degrees rotation of the wrist will cause points to squeeze at center because they just move from one position to the other, it doesn't take into account that the hand is a solid object, not just a cluster of disconnected points
 * - the smoothing is linked to the speed of tracking, smoothing movements with animations during rendering phase would alleviate the jerkiness on low frame rate tracking
 */
class KalmanFilterSmoothing {
  constructor() {
    this._allFiltersRefs = [];
    this._smoothed2dFilters = {};
    this._smoothed3dFilters = {};

    FpsManager.handTrackingFpsTracker.on('fps-changed', this._onHandTrackingFpsChanged.bind(this));
  }

  /**
   * Receive a single hand tracking data, and smoooooth it
   * WARNING: this method will edit the original data object !
   */
  handleTrackingInput(handData) {
    // 2D points
    if (handData.keypoints) {
      let keypoint;
      for (let i = 0; i < handData.keypoints.length; i++) {
        keypoint = handData.keypoints[i];
        this._upsert2dFilter(keypoint.name).smoothKeypoint(keypoint);
      }
    }

    // 3D points
    if (handData.keypoints3D) {
      let keypoint;
      for (let i = 0; i < handData.keypoints3D.length; i++) {
        keypoint = handData.keypoints3D[i];
        this._upsert3dFilter(keypoint.name).smoothKeypoint(keypoint);
      }
    }
  }

  _upsert2dFilter(ident) {
    if (typeof this._smoothed2dFilters[ident] === 'undefined') {
      this._smoothed2dFilters[ident] = new KalmanFilter2D(KALMAN_SETTINGS);
      this._allFiltersRefs.push(this._smoothed2dFilters[ident]);
    }
    return this._smoothed2dFilters[ident];
  }

  _upsert3dFilter(ident) {
    if (typeof this._smoothed3dFilters[ident] === 'undefined') {
      this._smoothed3dFilters[ident] = new KalmanFilter3D(KALMAN_SETTINGS);
      this._allFiltersRefs.push(this._smoothed3dFilters[ident]);
    }
    return this._smoothed3dFilters[ident];
  }

  _onHandTrackingFpsChanged(fps) {
    KALMAN_SETTINGS.Q = getKalmanQfromFps(fps);

    for (let i = 0; i < this._allFiltersRefs.length; i++) {
      this._allFiltersRefs[i].setMeasurementNoise( KALMAN_SETTINGS.Q );
    }
  }
}

export default KalmanFilterSmoothing;

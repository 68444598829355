import {Vector3} from 'three';

const _vecA = new Vector3();
const _vecB = new Vector3();

/**
 * A place to store some measurements of a 3D hand model in order to improve rigging afterward
 */
class RiggingProfile {
  constructor(rootBone) {
    this.bonesList = [];
    this.boneLengths = {};
    this.boneParents = {};
    this.boneChilds = {};
    this.knucklesDistances = {};

    this._extractProfileFromRootBone(rootBone);
  }

  _extractProfileFromRootBone(rootBone) {
    this._bonesMap = {};

    // first calculate lengths and extract infos
    this._recursivelyCalculateBoneLengths(rootBone);

    // then get hiearchy
    this._fetchBonesHierarchy();

    // then calculate knuckles spacings
    this._fetchKnucklesSpacings();

    // dont need this afterward
    delete this._bonesMap;
  }

  _getDistanceBetweenBones(boneA, boneB) {
    boneB.getWorldPosition(_vecA);
    boneA.getWorldPosition(_vecB);

    return _vecA.distanceTo(_vecB);
  }

  _recursivelyCalculateBoneLengths(bone, parent = null) {
    if (parent) {
      parent.updateMatrixWorld();
      // bone.updateMatrixWorld();
      this.boneLengths[bone.name] = this._getDistanceBetweenBones(bone, parent);
    } else {
      this.boneLengths[bone.name] = 0;
    }

    this.bonesList.push(bone.name);
    this._bonesMap[bone.name] = bone;

    let childBone;
    for (let i = 0; i < bone.children.length; i++) {
      childBone = bone.children[i];

      if (childBone.isBone) {
        this._recursivelyCalculateBoneLengths(childBone, bone);
      }
    }
  }

  _fetchBonesHierarchy() {
    let boneName, boneObj;
    for (let i = 0; i < this.bonesList.length; i++) {
      boneName = this.bonesList[i];
      boneObj = this._bonesMap[boneName];
      this.boneParents[boneName] = boneObj.parent && boneObj.parent.isBone ? boneObj.parent.name : null;
      this.boneChilds[boneName] = this._recursivelyCalculateBoneHierarchy(boneObj);
    }
  }

  _fetchKnucklesSpacings() {
    let fromBoneName, fromBoneObj, toBoneName, toBoneObj;
    for (let i = 0; i < this.knucklesBones.length; i++) {
      fromBoneName = this.knucklesBones[i];
      fromBoneObj = this._bonesMap[fromBoneName];

      this.knucklesDistances[fromBoneName] = {};

      for (let j = 0; j < this.knucklesBones.length; j++) {
        toBoneName = this.knucklesBones[j];
        toBoneObj = this._bonesMap[toBoneName];

        if (toBoneObj !== fromBoneObj) {
          this.knucklesDistances[fromBoneName][toBoneName] = this._getDistanceBetweenBones(fromBoneObj, toBoneObj);
        }
      }
    }
  }

  _recursivelyCalculateBoneHierarchy(bone, arr = []) {
    arr.push(bone.name);

    let childBone;
    for (let i = 0; i < bone.children.length; i++) {
      childBone = bone.children[i];

      if (childBone.isBone) {
        this._recursivelyCalculateBoneHierarchy(childBone, arr);
      }
    }

    return arr;
  }
}

RiggingProfile.prototype.knucklesBones = [
  'wrist',
  'thumb_cmc',
  'index_finger_mcp',
  'middle_finger_mcp',
  'ring_finger_mcp',
  'pinky_finger_mcp',
];

export default RiggingProfile;

import anime from 'animejs/lib/anime.es.js';

import AbstractVisibilityStrategy from './AbstractVisibilityStrategy.js';

/**
 * Implement a mechanism to hide un-tracked rig slowly and smoothly
 */
class FadeHideStrategy extends AbstractVisibilityStrategy {
  constructor(handRig) {
    super(handRig);

    this._material = null;
    this._currentAnimation = null;

    this._opacityVisible = 1;
    this._opacityInvisible = 0;
  }

  initializeAfterReady() {
    super.initializeAfterReady();

    this._setCurrentVisibility(this._armature.visible);

    this._material = this._handRig._skinnedMesh.material;
    this._material.transparent = true;
    this._material.opacity = this._currentlyVisible ? this._opacityVisible : this._opacityInvisible;
    this._material.alphaToCoverage = true;

    this.on('visibility-changed', (visible) => {
      this._fadeTo(visible ? this._opacityVisible : this._opacityInvisible);
    });
  }

  _updateArmatureVisibility() {
    this._armature.visible = this._material.opacity > 0;
  }

  _fadeTo(opacity = 0) {
    if (this._currentAnimation) {
      this._currentAnimation.pause();
    }

    this._currentAnimation = anime({
      targets: this._material,
      opacity: opacity,
      duration: 350,
      delay: 350,
      easing: opacity === 1 ? 'easeOutCirc' : 'easeInCirc',
      update: this._updateArmatureVisibility.bind(this),
      complete: this._updateArmatureVisibility.bind(this),
    });
  }
}

export default FadeHideStrategy;

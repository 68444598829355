/**
 * List of poses that a human hand can do (with infos on how to detect them)
 * @TODO detect thumb up/down
 * @TODO detect spoc hand
 * @TODO detect ok hand
 */
export default [
  {
    ident: 'fist',
    emoji: '✊',
    fingersState: {
      indexClosed: true,
      middleClosed: true,
      ringClosed: true,
      pinkyClosed: true,
      thumbClosed: [true, null],
    },
  },
  {
    ident: 'open-hand',
    emoji: '✋',
    fingersState: {
      indexClosed: false,
      middleClosed: false,
      ringClosed: false,
      pinkyClosed: false,
      thumbClosed: [true, null],
    },
  },
  {
    ident: 'open-hand-spread',
    emoji: '🖐',
    fingersState: {
      indexClosed: false,
      middleClosed: false,
      ringClosed: false,
      pinkyClosed: false,
      thumbClosed: false,
    },
  },
  {
    ident: 'victory-hand',
    emoji: '✌',
    fingersState: {
      indexClosed: false,
      middleClosed: false,
      ringClosed: true,
      pinkyClosed: true,
      thumbClosed: true,
    },
  },
  {
    ident: 'love-hand',
    emoji: '🤟',
    fingersState: {
      indexClosed: false,
      middleClosed: true,
      ringClosed: true,
      pinkyClosed: false,
      thumbClosed: false,
    },
  },
  {
    ident: 'horns-hand',
    emoji: '🤘',
    fingersState: {
      indexClosed: false,
      middleClosed: true,
      ringClosed: true,
      pinkyClosed: false,
      thumbClosed: true,
    },
  },
  {
    ident: 'phone-hand',
    emoji: '🤙',
    // emoji: '📞',
    fingersState: {
      indexClosed: true,
      middleClosed: true,
      ringClosed: true,
      pinkyClosed: false,
      thumbClosed: false,
    },
  },
  {
    ident: 'pointer-hand',
    emoji: '👉',
    fingersState: {
      indexClosed: false,
      middleClosed: true,
      ringClosed: true,
      pinkyClosed: true,
      thumbClosed: true,
    },
  },
  {
    ident: 'pistol-hand',
    emoji: '🔫',
    fingersState: {
      indexClosed: false,
      middleClosed: [true, false, null],
      ringClosed: true,
      pinkyClosed: true,
      thumbClosed: false,
    },
  },
  {
    ident: 'fu-hand',
    emoji: '🖕',
    fingersState: {
      indexClosed: [true, null],
      middleClosed: false,
      ringClosed: [true, null],
      pinkyClosed: [true, null],
      thumbClosed: [true, false, null],
    },
  },
  {
    ident: 'thumb-hand',
    emoji: '👍',
    fingersState: {
      indexClosed: [true, null],
      middleClosed: [true, null],
      ringClosed: [true, null],
      pinkyClosed: [true, null],
      thumbClosed: false,
    },
  },
];

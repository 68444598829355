export const BEHAVIOUR_TYPE_ABSTRACT = 'Behaviour';
export const BEHAVIOUR_TYPE_ALPHA = 'Alpha';
export const BEHAVIOUR_TYPE_ATTRACTION = 'Attraction';
export const BEHAVIOUR_TYPE_COLLISION = 'Collision';
export const BEHAVIOUR_TYPE_COLOR = 'Color';
export const BEHAVIOUR_TYPE_CROSS_ZONE = 'CrossZone';
export const BEHAVIOUR_TYPE_FORCE = 'Force';
export const BEHAVIOUR_TYPE_GRAVITY = 'Gravity';
export const BEHAVIOUR_TYPE_RANDOM_DRIFT = 'RandomDrift';
export const BEHAVIOUR_TYPE_REPULSION = 'Repulsion';
export const BEHAVIOUR_TYPE_ROTATE = 'Rotate';
export const BEHAVIOUR_TYPE_SCALE = 'Scale';
export const BEHAVIOUR_TYPE_SPRING = 'Spring';

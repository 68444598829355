import _isEqualWith from 'lodash/isEqualWith.js';
import _isArray from 'lodash/isArray.js';
import _find from 'lodash/find.js';

import MixinsComposer from '../utils/MixinsComposer.js'
import HasEventsMixin from '../mixins/HasEvents.js'

import CONFIG from '../config.js';
import CONTEXT from '../context.js';
import HANDS_POSES from '../datas/handsPoses.js';

import ValuesDebouncer from '../utils/ValuesDebouncer.js'
import AbstractTrackingDataTweaker from './AbstractTrackingDataTweaker.js';

const MIXINS = [
  HasEventsMixin,
];

/**
 * Receive hand tracking data and detect hand poses from it
 * @TODO display a 3D transparent sphere in the scene to illustrate the zone (for debug) ?
 *
 * May emit those events :
 * - pose-begun         = after a hand pose has begun
 * - pose-ended         = after a hand pose has ended
 * - poses-changed      = when the list of current poses changed
 */
class HandPoseDetector extends AbstractTrackingDataTweaker {

  constructor(handRig) {
    super(...arguments);

    MixinsComposer.construct(this, MIXINS);

    this._handRig = handRig;

    this._centerPoints = [];
    this._sphereCenter = {x: 0, y: 0, z: 0};
    this._sphereRadius = 0;
    this._sphereTransitionDistance = 0;

    // fingers states
    this._fingersState = {
      indexClosed: false,
      middleClosed: false,
      ringClosed: false,
      pinkyClosed: false,
      thumbClosed: false,
    };

    // poses states
    const posesState = {};
    this._posesList = HANDS_POSES.map((value) => { posesState[value.ident] = false; return value.ident; });
    this._posesState = new ValuesDebouncer(posesState, CONFIG.SMOOTH_POSE_TRACKING ? 200 : 0);
    this._posesState.on('value-changed', this._onPosesStateValueChanged.bind(this));

    // to show a view of this in the UI
    CONTEXT.APP.registerHandPoseDetector(this);
  }

  /**
  * Detect hand poses from tracking data
  */
  handleTrackingInput(handData) {
    if (handData.keypoints3D) {
      // @TODO I do the exact same thing in TrackingDataIK, useless work
      const pointsByName = {};
      for(let i = 0; i < handData.keypoints3D.length; i++) {
        pointsByName[ handData.keypoints3D[i].name ] = handData.keypoints3D[i];
      }

      this._centerPoints = [
        pointsByName.index_finger_mcp,
        pointsByName.pinky_finger_mcp,
        pointsByName.thumb_mcp,
        pointsByName.wrist,
      ];

      // update sphere
      this._updateSphereSpecs();

      // update fingers state
      this._fingersState.indexClosed = this._isPointInsideSphere(pointsByName.index_finger_tip);
      this._fingersState.middleClosed = this._isPointInsideSphere(pointsByName.middle_finger_tip);
      this._fingersState.ringClosed = this._isPointInsideSphere(pointsByName.ring_finger_tip);
      this._fingersState.pinkyClosed = this._isPointInsideSphere(pointsByName.pinky_finger_tip);
      this._fingersState.thumbClosed = this._isPointInsideSphere(pointsByName.thumb_tip);

      // update poses detection
      this._setCurrentPoses(this._getMetPoses());
    }
  }

  tickInvisible() {
    this._setCurrentPoses([]);
  }

  // return true = inside, false = outside, null = in-between
  _isPointInsideSphere(point) {
    const distanceFromCenter = this._getDistanceBetweenPoints(this._sphereCenter, point);

    if (distanceFromCenter <= (this._sphereRadius - this._sphereTransitionDistance)) {
      return true;
    }

    if (distanceFromCenter > (this._sphereRadius + this._sphereTransitionDistance)) {
      return false;
    }

    return null;
  }

  _getMetPoses() {
    const output = [];

    const customizer = function(valueA, valueB) {
      // handles array of values
      if (_isArray(valueB)) {
        for (let i = 0; i < valueB.length; i++) {
          if (valueB[i] === valueA) {
            return true;
          }
        }
        return false;
      }

      // otherwise it will proceed with the default check...
    };

    let poseData;
    for (let i = 0; i < HANDS_POSES.length; i++) {
      poseData = HANDS_POSES[i];

      if (_isEqualWith(this._fingersState, poseData.fingersState, customizer)) {
        output.push(poseData);
      }
    }

    return output;
  }

  _updateSphereSpecs() {
    // update center
    this._sphereCenter = this._getCenterFromPoints(this._centerPoints);

    // update radius
    this._sphereRadius = 0;
    let distance;
    for (let i = 0; i < this._centerPoints.length; i++) {
      distance = this._getDistanceBetweenPoints(this._sphereCenter, this._centerPoints[i]);

      if (distance > this._sphereRadius) {
        this._sphereRadius = distance;
      }
    }

    // fine-tuning
    this._sphereRadius *= 1.4;
    this._sphereTransitionDistance = this._sphereRadius * 0.1;
  }

  _setCurrentPoses(currentPoses = []) {
    const currentPosesIdents = currentPoses.map((pose) => { return pose.ident; });

    let poseIdent;
    for (let i = 0; i < this._posesList.length; i++) {
      poseIdent = this._posesList[i];

      this._posesState[poseIdent] = currentPosesIdents.indexOf(poseIdent) !== -1;
    }
  }

  _onPosesStateValueChanged(ident, nv, ov) {
    const changedPose = _find(HANDS_POSES, {ident: ident});

    this.emit(nv ? 'pose-begun' : 'pose-ended', changedPose);

    const currentPoses = [];
    let pose;
    for (let i = 0; i < HANDS_POSES.length; i++) {
      pose = HANDS_POSES[i];

      if (this._posesState[pose.ident] === true) {
        currentPoses.push(pose);
        break; // @TODO the break prevent multiple hand poses to active simultaneously, not sure if good idea
      }
    }

    this.emit('poses-changed', currentPoses);
  }
}

MixinsComposer.extend(HandPoseDetector).with(...MIXINS);

export default HandPoseDetector;
